<template>
  <v-app>
    <component :is="layout">
             <router-view :layout.sync="layout"/>
    </component>
  </v-app>
</template>



<script>
  //import AppLayoutMain from '@/views/AppLayoutMain'
const  defaultLayout = 'web-layout'
export default {
  // components:
  // {
  //   AppLayoutMain
  // }

  computed:{
    layout (){
      return (this.$route.meta.layout || defaultLayout)
    }
  }
}

</script>

<style >


</style>

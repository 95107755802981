<template>
    <v-autocomplete  :items="info"
           item-text="name"
           item-value="uuid"
            autocomplete="off"
            outlined
            v-model="peticion"
            append-icon="mdi-star"
            @change="validate(peticion)"
        >
    </v-autocomplete>
</template>

<script>
export default {
    name:'Autocomplete-Options',
    props:['name','info'],
    data(){
        return{
            peticion:''
        }
    },
    methods: {
        validate(item){
            this.$emit('showForm',item)
        }
    },

}
</script>

<style>

</style>
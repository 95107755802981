<template>
   <v-container>
        <v-row>
            <v-col cols="4">
                <button class="btn-submit" @click="$router.push('/')">Bay Bek</button>
            </v-col>
        </v-row>
   </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>
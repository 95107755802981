<template>
       <a  :href="link" :download="nameDescarga" class="link-pdf">
                <img src="~@/assets/images/icon_pdf.png" />
                  {{name}}
        </a>  
</template>

<script>
export default {
    props:['name','link','nameDescarga'],
    data(){
        return{
            
        }
    }

}
</script>

<style>

</style>
<template>
   <v-container>
        <AppContentCenterTitleAndEmail />
         <div id="preface">
           <p><strong>Prome cu bo persona cuminsa yena e formulario digital, mester tin e siguiente documentonan na man:</strong></p>
           <p>   - Number di telefon valido. <br> 
                 - E-mail adres valido.  <br> 
                 - ID valido cu number di ID completo di cada miembro di famia. <br>
                 - Carchi di Departamento di Impuesto (DIMP) cu bo ‘Persoonsnummer’ riba dje. <br>
                 - Carchi di banco cu e number di cuenta coriente riba dje (si bo tin un cuenta coriente na un banco). <br>
                 - Ultimo 3 payslip di bo persona, pareha y/of yiu den cas cu ta traha. <br>
           </p>
            <p>
                <strong>Pa Peticionnan cu ta rekeri di DPL:</strong>
            </p>
            <p>
                Pa peticion nobo mester registra via e link aki: <a href="https://dpl.aw/562-2/" target="_blank">Website DPL</a><br>
                Pa aumento: mester tin e schedule/afspraakkaart di DPL disponibel y upload esaki den e formulario pa por hasi e peticion di aumento<br>
            </p>

            <p>
                <strong>Pa Peticionnan cu ta rekeri di Medwork:</strong>
            </p>
            <p>
                Mester busca un carta di dokter na e seccion di Bijstand sea na Oranjestad of na San Nicolas. Cu esaki bo persona ta bay bo dokter di cas of specialista. Finalmente mester hiba e carta aki na MedWork unda cu lo hanja un cita.

            </p>

            <p>
               Tene bon na cuenta cu dependiendo di bo caso, ta posibel cu bo persona lo mester cumpli cu mas rekisito di cual bo persona tin maximo 30 dia pa cumpli cu ne. Si bo persona laga e 30 dianan pasa y no a cumpli cu esakinan, lo mester cuminsa cu e peticion ful di nobo. 
              Pa tur otro rekisito bo persona lo wordo informa door di DAS su seccion di Bijstand.
           </p>
    </div>



       <div>
        <router-link :to="{ name: 'AppLayoutFormNewBijstand'}" class="button online" title="Aplica pa Bijstand">Aplica pa bijstand</router-link>
        </div>
        <div>
          <router-link to="/forms/pagoDaAumento" class="button online" title="Peticion pa aumento" >Peticion pa aumento</router-link>   
        </div>
        <div>
            <router-link :to="{ name: 'AppContentChangesMenu' }" class="button online" title="Notificacion di cambio(nan)">Notificacion di cambio(nan)</router-link>
        </div>
        <div>
            <router-link :to="{ name: 'AppContentDownloadableFormsMenu' }" class="button" title="Formularionan">Formularionan</router-link>
        </div>
   </v-container>
</template>

<script>
import AppContentCenterTitleAndEmail from '../components/AppContentCenterTitleAndEmail'

export default {
    components: {
        AppContentCenterTitleAndEmail
    }
}

</script>
<template>
  <table class="amt">
    <thead>
      <tr>
        <td class="td">Nomber di banco</td>
        <td class="td">Number di cuenta</td>
        <td class="td">Suma total den AWG</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item,index) of Data" :key="index">
        <td class="td">
          <v-autocomplete
            :items="Bancos"
            item-value="uuid"
            item-text="name"
            autocomplete="off"
            outlined
            placeholder="Banco"
            append-icon="mdi-arrow-down-drop-circle"
            v-model="Data[index].nomberDiBanco"
          >
          </v-autocomplete>
        </td>
        <td class="td">
          <v-text-field 
             type="text" 
             v-mask="'####################'" 
             placeholder="Nr. di  cuenta"
             v-model="Data[index].numberDiCuenta"
             outlined
             ></v-text-field>
        </td>
        <td class="td">
          <v-text-field type="number"  value="0" outlined v-model="Data[index].sumaTotal"></v-text-field>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
    props:['Bancos','Data'],
    data(){
        return{

        }
    }
};
</script>

<style>
</style>
<template>
  <v-container>
    <div id="icon"><img src="~@/assets/images/icons/icon_welfare.png" alt="Bijstand" /></div>			
	<h3 align="center">{{title}} </h3>
   </v-container>
</template>
<script>
export default {
     props:['title']
}
</script>
<template>
   <v-container>
        <v-col cols="12" style="position:relative">
                    <img src="../../assets/images/icon_scan.png">
                    <span class="text-icon">Attach scanned documents</span>
                    <p class="mb-0">{{name}}</p>
                </v-col>
                <v-col cols="11" class="mx-auto container-radio " :class="{'input-container':!inputActive,'input-error':inputActive}">
                    <input type="file" class="input-File" @change="uploadFileTemp($event)" ref="MyFile" accept=".pdf, .jpg, .jpeg, .png" />
                    <span class="texto-error" v-show="inputActive">Documento(nan) obligatorio </span>
                </v-col>
                <v-col cols="12" style="position:relative">
                    <img src="../../assets/images/icon_important.png" class="icon-important" />
                    <span class="text-format">Documento cu wordo uploaded no por ta mas grandi cu {{size}}Mb . Solamente documentonan di e siguiente formatonan ta permiti:{{format}} </span>
                 </v-col>
   </v-container>
</template>

<script>
import axios from 'axios'
export default {
    name:'UploadFile', 
    props:['name','size','format','id','fileTypeEnum','fileSection','numChild','idFile'],
    data(){
        return{
            fileImg:{},
            isAplicant:false,
            isPareha:false,
            isChild:false,
            voidInput:true,
        }
    },
    computed:{
        inputActive:function(){
            let active = this.idFile === '' ? true : false;
            return active;
        }
    },
    methods: {
        //funcion para validar el archivo adjuntado
        uploadFileTemp(e){
            var SIzeFile = this.size * 1000000
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            this.fileImg = files[0]
            // valdiamos que el file sea del formato que permitimos .pdf, .png o .jpg
            if(this.fileImg.type === "application/pdf" || this.fileImg.type === "image/png" || this.fileImg.type === "image/jpg" || this.fileImg.type === "image/jpeg"){
                // si es del formato correcto validamos que el tamaño del file no exceda el admitido
                 if(this.fileImg.size > SIzeFile){
                    this.$refs.MyFile.value = null;
                    let message = `Er is een fout opgetreden, het bestand is groter dan ${this.size}Mb`
                    this.$emit('error',message)
                }else{
                    //    si no es mayor el tamaño ejecutamos la funcion para agg o remplace file
                        this.addFileRequest(this.fileImg)
                }
            }else{
                this.$refs.MyFile.value = null;
                let message = `bestandsformaatfout, u kunt alleen bestanden toevoegen, .pdf, .png of .jpeg`
                this.$emit('error',message)
                
            }
        },
        // function para validar de que seccion viene el archivo, pareja, aplicante o hijo
        selectType(type){
            this.isAplicant = type === 'APLICANTE' ? true : false
            this.isPareha = type === 'PAREHA' ? true : false
            this.isChild = type === 'CHILD' ? true : false
        },
        // funcion para enviar el file, o reemplazar file
        async addFileRequest(file){
            this.$emit('active');
            const key = localStorage.getItem('requestKey');
            try{
                // ejecutamos funcion de asignacion de typeEnum
                await this.selectType(this.fileTypeEnum)
                let response = []
                // validamos si es un file nuevo o un replace
                if(this.idFile === '') {
                    //    creamos un file nuevo
                        let formData = new FormData();
                        formData.append("File", file);
                        formData.append("key", key);
                        formData.append("FileTypeEnum", this.fileSection);
                        formData.append("IsFromApplican", this.isAplicant);
                        formData.append("IsFromSpouse",this.isPareha);
                        formData.append("IsFromChild",this.isChild);
                        formData.append("ChildIndex", this.numChild);
                        response = await axios.post(`/api/requests/files/key/${key}/add`, formData, {
                        crossDomain:true,
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                            "Content-Type": "multipart/form-data",
                        }
                        })
                }else{
                    // ejecutamos replace de file
                    let formData = new FormData();
                        formData.append("RequestFile", file);
                        formData.append("id",this.idFile)
                        formData.append("key", key);
                        response = await axios.post(`/api/requests/files/key/${key}/replace`, formData, {
                        crossDomain:true,
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                            "Content-Type": "multipart/form-data",
                        }
                        })
                }
                    // si es exitosa la peticion ejecutamos los emit para desactivar
                    // overlay enviar msj al usuario y asignar el id del file
                    if(response.status === 200){
                        if(this.numChild > 0){
                            this.$emit('asigned',this.fileSection,response.data.id,this.numChild) 
                        }else{
                            this.$emit('asigned',this.fileSection,response.data.id) 
                        }
                        
                        this.$emit('desactive');
                        this.$emit('success',this.fileImg.name)
                    }
                    console.log(response.data)
            }catch(err){
                this.$emit('desactive');
                this.$emit('error','ocurrio un error')
                this.$refs.MyFile.value = null;
                console.log(err);
               
            }
        },
     }
}
</script>

<style>
 .input-File{
     width: 100% !important;
 }
 .input-container{
     border: 1px solid #429cc6 !important;
 }
 .input-error{
     border: 1px solid red !important;
 }
 .texto-error{
     color:red !important;
 }
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import VueTheMask from 'vue-the-mask'
import VueCurrencyFilter from 'vue-currency-filter'
import VueHtml2Canvas from 'vue-html2canvas';
import VueAnalytics from 'vue-analytics'

import 'regenerator-runtime/runtime'

Vue.use(VueTheMask)

Vue.use(VueCurrencyFilter, {
  symbol: '$', // El símbolo, por ejemplo €
  thousandsSeparator: ',', // Separador de miles
  fractionCount: 2, // ¿Cuántos decimales mostrar?
  fractionSeparator: '.', // Separador de decimales
  symbolPosition: 'front', // Posición del símbolo. Puede ser al inicio ('front') o al final ('') es decir, si queremos que sea al final, en lugar de front ponemos una cadena vacía ''
  symbolSpacing: true // Indica si debe poner un espacio entre el símbolo y la cantidad
})

Vue.use(VueHtml2Canvas);
// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-220097992-1'
});
 
//import templates layout
import WebLayout from './Layout/WebLayout.vue'
import LoginLayout from './Layout/LoginLayout.vue'
import DashboardLayout from './Layout/DashboardLayout.vue'

Vue.component('web-layout', WebLayout);
Vue.component('login-layout', LoginLayout);
Vue.component('dashboard-layout', DashboardLayout);

//axios.defaults.baseURL = 'http://dev-project-das.xa-tech.com:5553';
axios.defaults.baseURL = 'https://loketsocial.das.aw:444';
axios.defaults.timeout = 100000;

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  axios,
  VueTheMask,
  render: h => h(App)
}).$mount('#app')

<template>
            <v-dialog max-width="400" v-model="modal">
            <v-card>
                <v-card-title class="title-modal-confirmation">
                        <v-row>
                            <v-col cols="9" class="text-center"><h4 class="white--text">{{title}} </h4></v-col>
                            <v-col cols="3" class="text-right pt-2">  
                                <v-btn icon large color="white"  @click="$emit('closeModal')"><v-icon class="icon-close" color="white">mdi-close</v-icon> </v-btn>
                            </v-col>
                        </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <v-icon  large color="success" class="icon-message" v-if="success">mdi-check-circle</v-icon> <br>
                            <v-icon  large color="error" class="icon-message" v-if="error">mdi-close-circle</v-icon> <br>
                            <h3 v-if="success">
                                <p v-html="msjSuccess"></p>
                            </h3>
                            <h3 v-if="error">
                                {{msjError}}
                            </h3>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
</template>

<script>
export default {
    props:['modal','title','msjError','msjSuccess','error','success']
}
</script>

<style lang="scss">
    .title-modal-confirmation{
       padding: 8px 24px 10px !important;
       height: 50px !important;
       background: #429cc6 !important;
       h4{
           font-size:19px !important;
           @media(min-width: 326px) and (max-width: 420px){
               font-size: 15px !important;
           }
           @media(max-width: 325px){
               font-size: 13px !important;
           }
       }
   }
</style>
<template>
        <v-row class="contenedor-peticion">
                <span class="container-title">6. Vivienda</span>
                <v-col cols="11" class="mx-auto container-radio">
                    <p>Famia tin cas propio?</p>
                    <v-icon class="icon-start">mdi-star</v-icon>
                     <v-radio-group  v-model="datos.famiaTinCasPropio" :rules="[rul.required]" >
                       <v-row>
                            <v-col cols="6">
                                  <v-radio label="Si"  value="Si" @click="$emit('validateVivienda',datos.famiaTinCasPropio)"></v-radio>
                            </v-col>   
                            <v-col cols="6">
                                <v-radio label="No"  value="No" @click="$emit('validateVivienda',datos.famiaTinCasPropio)"></v-radio>
                            </v-col>                         
                       </v-row>
                     </v-radio-group >
                </v-col>
                 <v-col cols="11" class="mx-auto container-radio" v-show="showHuur">
                    <p>Famia ta huur cas?</p>
                    <v-icon class="icon-start">mdi-star</v-icon>
                     <v-radio-group  v-model="datos.famiaTaHuurCas">
                       <v-row>
                            <v-col cols="6">
                                  <v-radio label="Si"  value="Si" @click="$emit('validateHuur',datos.famiaTaHuurCas)"></v-radio>
                            </v-col>   
                            <v-col cols="6">
                                <v-radio label="No"  value="No" @click="$emit('validateHuur',datos.famiaTaHuurCas)"></v-radio>
                            </v-col>                         
                       </v-row>
                     </v-radio-group >
                </v-col>
                <v-col cols="12" class="celda mb-4" v-show="showDiKien" >
                    <span class="label-form">For di ken?</span>
                     <v-text-field type="text" append-icon="mdi-star" outlined v-model="datos.forDiKien" ></v-text-field>
                </v-col>
                <v-col cols="11" class="mx-auto container-radio" v-show="showContainer">
                    <p>Famia ta bibando cerca otro hende?</p>
                    <v-icon class="icon-start">mdi-star</v-icon>
                     <v-radio-group v-model="datos.famiaTaBibandoCercaOtroHende">
                       <v-row>
                            <v-col cols="6">
                                  <v-radio label="Si"  value="Si" @click="$emit('validateHende',datos.famiaTaBibandoCercaOtroHende)"></v-radio>
                            </v-col>   
                            <v-col cols="6">
                                <v-radio label="No"  value="No" @click="$emit('validateHende',datos.famiaTaBibandoCercaOtroHende)"></v-radio>
                            </v-col>                         
                       </v-row>
                     </v-radio-group >
                </v-col>
                 <v-col cols="12" class="celda mb-4"  v-show="showContainer3">
                    <span class="label-form">Serca di ken?</span>
                     <v-text-field type="text" append-icon="mdi-star" outlined v-model="datos.sercaDiKien" ></v-text-field>
                </v-col>
                <v-col cols="12" class="celda mb-4"  v-show="showContainer2">
                    <span class="label-form">Unda famia ta biba?</span>
                     <v-text-field type="text" append-icon="mdi-star" outlined  v-model="datos.undaFamiaTaBiba"></v-text-field>
                </v-col>

        </v-row>
</template>

<script>
export default {
    name:'FormVivienda',
    props:['datos','showHuur','showContainer','showContainer2','showContainer3','showDiKien'],

    data(){
      return{
            rul: {
                required: value => !!value ||  'E veld aki ta obligatorio',
                email: value => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/.test(value) || 'E email adres no ta den un formato corecto',
                selectReq: value => !!value || 'Por fabor schohe un opcion',
                min6: v => (v || '').length >= 6 || 'Introducir minimo 6 caracteres',
                max6: v => (v || '').length <= 12 || 'Introducir máximo 12 caracteres',
               phone: value => /^[0-9.*()-_#]{0,16}$/.test(value) || 'phone format incorrect'
            },
      }
    }

}
</script>

<style>

</style>
<template>
        <v-row  class="px-4 mb-10 card-data">

                <Snackbar
                :datos="dataSnackbar"
                ></Snackbar>

                <v-overlay :value="overlayFile">
                    <v-progress-circular
                    indeterminate
                    color="info"
                    ></v-progress-circular>
                    <br>
                </v-overlay>
                
                <div class="title-data">
                    <p>{{titleSection}}  </p>
                </div>
                <v-col cols="12">

                    <!-- 2 . Datonan aplicante -->
                    <v-row class="contenedor-peticion">
                            <span class="container-title">2. Datonan </span>
                            <v-col cols="12"  class="celda">
                                <span class="label-form">Fam soltero</span>
                                <v-text-field type="text" append-icon="mdi-star"  outlined v-model="datos.datonan.fam" :rules="[rul.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"  class="celda" >
                                <span class="label-form">Prome nomber(nan)</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined v-model="datos.datonan.nan" :rules="[rul.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12"  class="celda" >
                                <span class="label-form">Adres</span>
                                <v-row>
                                    <v-col cols="8">
                                        <v-autocomplete 
                                        :items="adres"
                                        item-text="name"
                                        item-value="name"
                                        outlined
                                        append-icon="mdi-star" 
                                        v-model="datos.datonan.adres"
                                        :rules="[rul.selectReq]"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field type="text" append-icon="mdi-star" outlined placeholder="#" v-model="datos.datonan.nummerAdres" :rules="[rul.required]"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12"   class="celda">
                                <span class="label-form">Distrito</span>
                                <v-autocomplete   :items="District"
                                    item-value="uuid" item-text="name"
                                    v-model="datos.datonan.district"
                                    autocomplete="off"
                                    outlined
                                    append-icon="mdi-star"
                                    :rules="[rul.selectReq]"
                                    >
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="12"   class="celda">
                                <span class="label-form">Fecha di nacemento (dd-mm-yyyy | Dia / Luna / Aña)</span>
                                <v-dialog ref="dialog" v-model="modal_date" :return-value.sync="datos.datonan.fechaDiNacimento" persistent width="290px" >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="datos.datonan.fechaDiNacimento"  append-icon="mdi-star" readonly v-bind="attrs" v-on="on" outlined="" :rules="[rul.required]"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="datos.datonan.fechaDiNacimento" scrollable  >
                                                <v-spacer></v-spacer>
                                                <v-btn text color="error" @click="modal_date = false ">Annuleren</v-btn>
                                                <v-btn  text  color="primary"  @click="$refs.dialog.save(datos.datonan.fechaDiNacimento)" >Accepteren</v-btn>
                                            </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12"   class="celda">
                                <span class="label-form">Lugar di nacemento</span>
                                <v-autocomplete  :items="countrys"
                                    item-value="name" item-text="name_en"
                                    v-model="datos.datonan.lugarDiNacemento"
                                    autocomplete="off"
                                    outlined
                                    append-icon="mdi-star"
                                    :rules="[rul.selectReq]"
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12"   class="celda">
                                <span class="label-form">Nacionalidad</span>
                                <v-autocomplete   :items="countrys"
                                    item-value="name" item-text="name_en"
                                    v-model="datos.datonan.nacionalidad"
                                    autocomplete="off"
                                    outlined
                                    append-icon="mdi-star"
                                    :rules="[rul.selectReq]"
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12"  class="celda" >
                                <span class="label-form">Number di telefon</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined v-model="datos.datonan.numberDiTelefon" :rules="[rul.required,rul.phone,rul.min6,rul.max6]"></v-text-field>
                            </v-col>
                            <v-col cols="12"  class="celda">
                                <div class="note2 text-justify">
                                    <p >Pa bo peticion di bijstand por keda procesa mas lihe posibel,
                                        ta hopi importante pa pone un e-mail adres valido. Tene na cuenta
                                        cu bo persona lo ricibi tur confirmacion via e e-mail adres.</p>
                                </div>
                                <span class="label-form">Email</span>
                                <v-text-field type="email" append-icon="mdi-star" outlined  v-model="datos.datonan.email" :rules="[rul.email]"></v-text-field>
                            </v-col>
                            <v-col cols="11" class="mx-auto container-radio" >
                                <p>Sexo:</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="datos.datonan.sexo" :rules="[rul.required]">
                                <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-radio label="Masculino"  value="Masculino"></v-radio>
                                        </v-col>   
                                        <v-col cols="12" sm="6" md="6">
                                            <v-radio label="Femenino"  value="Femenino"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <v-col cols="12"   class="celda" v-if="showEstadoCivil">
                                <span class="label-form">Estado Civil</span>
                                <v-autocomplete  :items="estadoCivil"
                                    item-value="uuid" item-text="name"
                                    v-model="datos.datonan.estadoCivil"
                                    @change="changeEstadoCivil(datos.datonan.estadoCivil)"
                                    autocomplete="off"
                                    outlined
                                    :rules="[rul.selectReq]"
                                    append-icon="mdi-star"
                                    >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12"  class="celda" v-if="casada" >
                                <span class="label-form">Yena fecha cu a casa</span>
                                <v-text-field type="date" outlined  v-model="datos.datonan.fechaCasa" :rules="[rul.required]"></v-text-field>
                            </v-col>

                            <v-col cols="12"  class="celda" v-if="divorcia">
                                <span class="label-form">Yena fecha cu a divorcia</span>
                                <v-text-field type="date"  v-model="datos.datonan.fechaDivorcia" outlined  :rules="[rul.required]"></v-text-field>
                            </v-col>

                            <!-- fin validacion estado civil -->

                            <v-col cols="12"  class="celda" >
                                <span class="label-form">Number di cedula</span>
                                <v-text-field type="text" append-icon="mdi-star" v-model="datos.datonan.numberDiCedula" v-mask="'##-##-##-##'" outlined :rules="[rul.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12"  class="celda" v-if="type === 'noboPaBijstand'">
                                <span class="label-form">Persoonsnummer</span>
                                <v-text-field type="text" append-icon="mdi-star"   outlined :rules="[rul.required]"></v-text-field>
                            </v-col>
                            
                            <UploadFile 
                                name="Upload carchi di DIMP cu 'persoonsnummer' riba dje." 
                                :size=30 
                                format="PDF, JPG and PNG"
                                :fileTypeEnum='titleSection'
                                :fileSection=201
                                :numChild=0
                                :idFile="datos.datonan.idPersonummer"
                                @active="activeOverlay"
                                @desactive="desactiveOverlay"
                                @asigned="asignedIdRequestFile"
                                @error="errorFile"
                                @success="successFile"
                            ></UploadFile>
                            
                            <!-- Bo persona tin un cuenta coriente na un banco local? -->

                            <v-col cols="11" class="mx-auto container-radio" >
                                <p>Bo persona tin un cuenta coriente na un banco local?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="datos.datonan.tinCuentaBancoLocal" :rules="[rul.required]">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- condicion di cuenta banco = SI -->

                            <v-col cols="12"  class="celda" v-if="datos.datonan.tinCuentaBancoLocal === 'Si'">
                                    <span class="label-form">Nomber di banco</span>
                                    <v-autocomplete :items="bancos"
                                    item-value="uuid" item-text="name"
                                    autocomplete="off"
                                    outlined
                                    append-icon="mdi-star"
                                    v-model="datos.datonan.nomberDiBanco"
                                    :rules="[rul.selectReq]"
                                    >
                                    </v-autocomplete>
                            </v-col>

                            <v-col cols="12" class="celda"  v-if="datos.datonan.tinCuentaBancoLocal === 'Si'">
                                <span class="label-form">Number di cuenta coriente</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined  v-model="datos.datonan.numberDiCuentaCorriente" :rules="[rul.required]"></v-text-field>
                            </v-col>
                            
                            <v-col cols="11" class="mx-auto" v-if="datos.datonan.tinCuentaBancoLocal === 'Si'">
                                <div class="note2">
                                    <p>NO ATM CARDS!!!</p>
                                </div>
                            </v-col>
                        

                            <UploadFile 
                                v-if="datos.datonan.tinCuentaBancoLocal === 'Si'"
                                name="caarchi oficial di banco" 
                                :size=30 
                                format="PDF, JPG and PNG"
                                :fileTypeEnum='titleSection'
                                :fileSection=301
                                :numChild=0
                                :idFile="datos.datonan.idCartaDiBanco"
                                @active="activeOverlay"
                                @desactive="desactiveOverlay"
                                @asigned="asignedIdRequestFile"
                                @error="errorFile"
                                @success="successFile"
                            ></UploadFile>

                            <!-- fin validate cuenta di banco -->


                            <v-col cols="11" class="mx-auto container-radio" >
                                <p>Bo persona ta traha?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="datos.datonan.personataTraha" :rules="[rul.required]">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si" @click="validateToTraha(datos.datonan.personataTraha)"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No" @click="validateToTraha(datos.datonan.personataTraha)"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>


                            <!-- condicion si traha = SI -->

                            <v-col cols="12" class="celda"  v-if="siTraha">
                                <span class="label-form">Nomber di compania</span>
                                <v-text-field type="text" append-icon="mdi-star"  v-model="datos.datonan.nomberDiCompania"  outlined :rules="[rul.required]"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="celda"  v-if="siTraha">
                                <span class="label-form">Salario bruto (AWG)</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined  v-model="datos.datonan.salarioBruto" :rules="[rul.required]"></v-text-field>
                            </v-col>
                            <v-col cols="11" class="mx-auto mt-5 contenedor-peticion" v-if="siTraha">
                                <span class="container-title">Payslip 1</span>

                                <UploadFileNoObligatorio
                                    name="Upload file" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    :fileTypeEnum='titleSection'
                                    :fileSection=101
                                    :numChild=0
                                    :idFile="datos.datonan.idPayslip1"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFileNoObligatorio>

                        </v-col>
                        <v-col cols="11" class="mx-auto mt-5 contenedor-peticion" v-if="siTraha">
                                <span class="container-title">Payslip 2</span>

                                <UploadFileNoObligatorio
                                    name="Upload file" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    :fileTypeEnum='titleSection'
                                    :fileSection=102
                                    :numChild=0
                                    :idFile="datos.datonan.idPayslip2"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFileNoObligatorio>

                        </v-col>
                        <v-col cols="11" class="mx-auto mt-5 contenedor-peticion" v-if="siTraha">
                                <span class="container-title">Payslip 3</span>

                                <UploadFileNoObligatorio
                                    name="Upload file" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    :fileTypeEnum='titleSection'
                                    :fileSection=103
                                    :numChild=0
                                    :idFile="datos.datonan.idPayslip3"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFileNoObligatorio>

                        </v-col>
                        <v-col cols="11" class="mx-auto mt-5 contenedor-peticion" v-if="siTraha">
                                <span class="container-title">Carta di Trabow</span>

                                <UploadFile 
                                    name="Upload file" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    :fileTypeEnum='titleSection'
                                    :fileSection=1001
                                    :numChild=0
                                    :idFile="datos.datonan.idCartaDiTrabaho"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFile>

                        </v-col>
                            
                            <!-- condicion si traga = No -->

                            <v-col cols="12" class="celda" v-if="noTraha" >
                                <span class="label-form">Unda a traha ultimo</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined v-model="datos.datonan.ultimoTraha" :rules="[rul.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12"  class="celda" v-if="noTraha">
                                <span class="label-form">Motibo cu no ta traha mas?</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined v-model="datos.datonan.motiboNoTrahaMas" :rules="[rul.required]"></v-text-field>
                            </v-col>

                            <v-col cols="11" class="mx-auto container-radio" v-if="noTraha">
                                <p>Ta dispuesto pa traha?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="datos.datonan.dispuestoPaTraha" :rules="[rul.required]">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- validate ta dispuesto pa trata = Si -->

                            <v-col cols="11" :id="`dpl-${idCard}`" class="celda mb-5 contenedor-peticion mx-auto mt-4" v-if="datos.datonan.dispuestoPaTraha === 'Si'" >
                            
                                <!-- <a  href="https://web.dpl.aw/562-2/" target="_blank" class="link-pdf">
                                        DPL registration
                                </a>   -->

                                   <span class="container-title">Upload carta di inscripcion di DPL</span>

                                <UploadFile 
                                    name="Upload Carta di inscripcion di DPL" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    :fileTypeEnum='titleSection'
                                    :fileSection=10002
                                    :numChild=0
                                    :idFile="datos.datonan.idCartaDidpl"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFile>

                                <v-alert type="error" >
                                    <p class="white--text">Si bo persona no tin e carta di inscripcion di DPL,
                                    bo persona mester subi e link y registra na e parti di DPL prome. Den caso cu bo
                                    persona tin sea e carta of schedule di DPL, por fabor attach esaki na e formulario.
                                    No por completa e peticion aki sin e carta di inscripcion di DPL. </p>
                                </v-alert>

                            </v-col>

                            <!-- <v-col cols="12" class="celda" v-if="datos.datonan.dispuestoPaTraha === 'Si'" >
                                <span class="label-form">Number di registracion di DPL</span>
                                <v-text-field type="text" v-model="datos.datonan.numberDiRegistracionDiDPL" v-mask="'DPL-#####'" placeholder="DPL-00000" append-icon="mdi-star" outlined :rules="[rul.required]" ></v-text-field>
                            </v-col> -->

                            <!-- fin ta dispuesto pa traha -->

                            <v-col cols="11" class="mx-auto container-radio" v-if="datos.datonan.dispuestoPaTraha === 'No'">
                                <p>Tin problema cu salud?</p>
                                <span>Si contesta si mester upload Cita di Medworks</span>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="datos.datonan.tinProblemaSalud" :rules="[rul.required]">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- validate Tin problema cu salud? = Si -->

                            <!-- <v-col cols="12" class="celda mb-5 text-center" v-if="datos.datonan.tinProblemaSalud === 'Si'" >
                                
                                <ButtonLinkDownload 
                                name="Declaracion di Dokter di cas/Specialista"
                                :link="downloadUrlEspecialista"
                                :nameDescarga="nameEspecialista"
                                ></ButtonLinkDownload>

                            </v-col> -->

                            <v-col cols="11" class="mx-auto mt-5 contenedor-peticion" v-if="datos.datonan.tinProblemaSalud === 'Si'">
                                <span class="container-title">Upload Cita di Medworks</span>

                                <UploadFile 
                                    name="Upload file" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    :fileTypeEnum='titleSection'
                                    :fileSection=601
                                    :numChild=0
                                    :idFile="datos.datonan.idCartaDoctor"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFile>

                        </v-col>
                        <!-- fin validate tin problema cu salud -->


                            <v-col cols="11" class="mx-auto container-radio" v-if="noTraha">
                                <p>Tin problema cu adiccion?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="datos.datonan.tinProblemaAdiccion" :rules="[rul.required]">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- validate tin problema cu adiccion = Si -->

                            <v-col cols="11" class="mx-auto container-radio" v-if="datos.datonan.tinProblemaAdiccion === 'Si'">
                                <p>Den caso di adiccion, ta siguiendo un tratamento of programa?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="datos.datonan.siguiendoTratamento" :rules="[rul.required]">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- fin validate problema cu adiccion -->

                            <!-- validate si tratamento of programa = Si -->

                            
                            <!-- validate si tratamento of programa = No -->

                            <v-col cols="12" class="celda mb-5 text-center"  v-if="datos.datonan.siguiendoTratamento === 'No'" >
                            
                            <ButtonLinkDownload
                                name="Download Carta FMAA"
                                :link="downloadUrlDeclaracion"
                                :nameDescarga="nameDeclaracion"
                            ></ButtonLinkDownload>

                            </v-col>


                            <v-col cols="11" class="mx-auto container-radio" v-if="noTraha">
                                <p>Ta bay scol den dia?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="datos.datonan.taBayScolDenDia" :rules="[rul.required]">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- validate if ta bay scol den dia = SI -->

                            <v-col cols="12"  class="celda" v-if="datos.datonan.taBayScolDenDia === 'Si'">
                                <span class="label-form">Nomber di scol</span>
                                <v-autocomplete :items="scols"
                                    item-value="name" item-text="name"
                                    autocomplete="off"
                                    outlined
                                    append-icon="mdi-star"
                                    :rules="[rul.selectReq]"
                                    v-model="datos.datonan.nomberDiScol"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="11" class="mx-auto container-radio" v-if="noTraha">
                                <p>Tin cuido mandatorio (zorgplicht):</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="datos.datonan.tinCuidoMandatorioZorgplicht" :rules="[rul.required]">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- validate if tin cuido mandatorio = SI -->

                            <v-col cols="12"  class="celda" v-if="datos.datonan.tinCuidoMandatorioZorgplicht === 'Si'">
                                <span class="label-form">Tin cuido mandatorio?</span>
                                <v-autocomplete :items="Options"
                                item-value="uuid" item-text="name"
                                autocomplete="off"
                                outlined
                                append-icon="mdi-star"
                                :rules="[rul.selectReq]"
                                v-model="datos.datonan.tinCuidoMandatorio"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="11" class="mx-auto container-radio" v-if="noTraha">
                                <p>Tin necesidad di Guia di un Trahado Social:</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="datos.datonan.tinNecesidadDiGuiaTrahado" :rules="[rul.required]">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <v-col cols="12" class="celda mb-5 text-center"  v-if="datos.datonan.tinNecesidadDiGuiaTrahado === 'Si'" >
                            
                                <ButtonLinkDownload
                                    name="Registracion Oficina pa Comunidad (OPC)"
                                    :link="downloadUrlRegistration"
                                    :nameDescarga="nameRegistration"
                                ></ButtonLinkDownload>

                                 <UploadFile 
                                    v-if="datos.datonan.tinNecesidadDiGuiaTrahado === 'Si'"
                                        name="Registracion Oficina pa Comunidad (OPC)" 
                                        :size=30 
                                        format="PDF, JPG and PNG"
                                        :fileTypeEnum='titleSection'
                                        :fileSection=801
                                        :numChild=0
                                        :idFile="datos.datonan.idCartaOPC"
                                        @active="activeOverlay"
                                        @desactive="desactiveOverlay"
                                        @asigned="asignedIdRequestFile"
                                        @error="errorFile"
                                        @success="successFile"
                                    ></UploadFile>

                            </v-col>

                            <v-col cols="11" class="mx-auto container-radio" v-if="noTraha && costelacion">
                                <p>Yiu ta bay casa dentro di 1 aña?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="datos.datonan.yiuTaBayCasa" :rules="[rul.required]">
                                <v-row> 
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>
                        <!--  -->
                        <!-- name="Upload e comprobante di Censo di e pareha"  -->
                            <UploadFile 
                            v-if="datos.datonan.yiuTaBayCasa === 'Si'"
                                name=" Upload e comprobante di duna man (ondertrouw)" 
                                :size=30 
                                format="PDF, JPG and PNG"
                                :fileTypeEnum='titleSection'
                                :fileSection=402
                                :numChild=0
                                :idFile="datos.datonan.idCartaPareha"
                                @active="activeOverlay"
                                @desactive="desactiveOverlay"
                                @asigned="asignedIdRequestFile"
                                @error="errorFile"
                                @success="successFile"
                            ></UploadFile>



                        <!-- fin validate ta traha = No -->

                    </v-row>

                    <!--3 otro entrada -->
                    <v-row class="contenedor-peticion mt-5">
                        <span class="container-title">3. Otro entrada(nan)(art.12 Lbbv)</span>
                        <v-col cols="11" class="mx-auto container-radio" >
                            <p>Tin otro entrada(nan)?</p>
                            <v-icon class="icon-start">mdi-star</v-icon>
                            <v-radio-group v-model="datos.otroEntrada.tinOtraEntrada" :rules="[rul.required]">
                            <v-row>
                                    <v-col cols="6">
                                        <v-radio label="Si"  value="Si" @click="mountedComponentOtroEntrada(datos.otroEntrada.tinOtraEntrada)"></v-radio>
                                    </v-col>   
                                    <v-col cols="6">
                                        <v-radio label="No"  value="No" @click="mountedComponentOtroEntrada(datos.otroEntrada.tinOtraEntrada)"></v-radio>
                                    </v-col>                         
                            </v-row>
                            </v-radio-group >
                        </v-col>

                        <v-col cols="12" class="celda" >

                            <component @pension="validarPension" :is='tablePension' :title="titleSection" :Data="datos.otroEntrada.tipoDiPension"></component>

                            <component @pension="validarEntrada" :is='tableEntrada' :title="titleSection" :Data="datos.otroEntrada.entradaNan"></component>

                        </v-col>

                    </v-row>

                    <!--4 alimentacion -->
                    <v-row class="contenedor-peticion">
                        <span class="container-title">4. Alimentacion (art.4 decreto nacional bijstand)</span>
                        <v-col cols="12">
                            <p>Den caso cu bo no tin informacion, no sa e paradero di e tata(nan) di bo yui(nan) y no
                            ta desea intermediacion di Directie Voogdijraad encuanto alimentacion, bo yiu of yiunan 
                            no lo bin na remarca pa nan parti(bijslag) den calculacion total di e suma cu lo bo bay 
                            ricibi na bijstand.
                        </p>
                        </v-col>
                        <v-col cols="11" class="mx-auto container-radio">
                            <p>Ta desea intermediacion di Directie Voogdijraad?</p>
                            <v-icon class="icon-start">mdi-star</v-icon>
                            <v-radio-group v-model="datos.alimentacion.taDeseaIntermediacionDiDirectieVoogdijraad" :rules="[rul.required]">
                            <v-row>
                                    <v-col cols="6">
                                        <v-radio label="Si"  value="Si"></v-radio>
                                    </v-col>   
                                    <v-col cols="6">
                                        <v-radio label="No"  value="No"></v-radio>
                                    </v-col>                         
                            </v-row>
                            </v-radio-group >
                        </v-col>

                        <v-col cols="11 mx-auto" v-if="datos.alimentacion.taDeseaIntermediacionDiDirectieVoogdijraad === 'Si'">
                            <div class="note2">
                                <p>Lesa instructionnan importante</p>
                            </div>
                            <p>Tene bon cuenta cu e siguiente pasobra e ta <span>obligatorio:</span></p>
                            <ol class="style01">
                                <li><span>Download</span> e formulario di Directie Voogdijraad. </li>
                                <li><span>Print </span>y <span>yena </span>esaki completo.</li>
                                <li><span>Firma</span> esaki.</li>
                                <li><span>Scan</span> esaki (of saca potret cu un telefon).</li>
                                <li><span>Upload</span> e formulario yena y firma bek na e seccion yama: "Upload formulario di Directie Voogdijraad"</li>
                            </ol>
                            <p>Si bo persona <span>no</span> ta dispone di un scanner, por entrega e formulario yena y firma na Departamento di Asunto 
                            Social su oficina situa na Oranjestad of na San Nicolaas.</p>
                            
                            <p>Si bo persona <span>no</span> cumpli cu e formulario di Directie Voogdijraad bo peticion pa bijstand <span>no</span> por keda procesa.</p>
                            
                            <ButtonLinkDownload
                            name="formulario Solicitud pa Alimentacion"
                            :link="downloadUrlAlimentacion"
                            :nameDescarga="nameAlimentacion"
                            ></ButtonLinkDownload>

                            <v-row class="contenedor-peticion mt-8">
                                <span class="container-title">Upload formulario di Directie Voogdijraad</span>

                                <UploadFile 
                                    name="Upload file" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    :fileTypeEnum='titleSection'
                                    :fileSection=501
                                    :numChild=0
                                    :idFile="datos.alimentacion.idFormularioDiDirectieVoogdijraad"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFile>
                            </v-row>

                        </v-col>

                        
                    </v-row>

                    <!--5 capital efectivo -->
                    <v-row class="contenedor-peticion">
                        <span class="container-title">5. Capital efectivo (art.6 Lbbv y art.16 Lmza)</span>
                        <v-col cols="12">
                            <p>Cabes di famia of otro miembro di famia tin cuenta na banco y/of di spaar, 
                                credito (manera cesantia, seguro, herencia) y otro capital contante?
                            </p>
                        </v-col>
                        <v-col cols="11" class="mx-auto container-radio">
                            <p>Capital efectivo</p>
                            <v-icon class="icon-start">mdi-star</v-icon>
                            <v-radio-group v-model="datos.CapitalEfectivo.capitalEfectivo" :rules="[rul.required]">
                            <v-row>
                                    <v-col cols="6">
                                        <v-radio label="Si"  value="Si" @click="mountedComponentCapitalEfectivo(datos.CapitalEfectivo.capitalEfectivo)"></v-radio>
                                    </v-col>   
                                    <v-col cols="6">
                                        <v-radio label="No"  value="No" @click="mountedComponentCapitalEfectivo(datos.CapitalEfectivo.capitalEfectivo)"></v-radio>
                                    </v-col>                         
                            </v-row>
                            </v-radio-group >
                        </v-col>

                        <v-col cols="12" class="">
                            <component 
                            :is='componentTableCapitalEfectivo'
                            :Bancos="bancos"
                            :Data="datos.CapitalEfectivo.capital"></component>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
</template>

<script>
import UploadFile from '../components/elements/UploadFile'
import UploadFileNoObligatorio from '../components/elements/UploadFileNoObligatorio'
import ButtonLinkDownload from '../components/elements/ButtonLinkDownload'
import TableCapitalEfectivo from '../components/elements/TableCapitalEfectivo'
import TableTipoPension from '../components/elements/TableTpoPension'
import TableEntrada from '../components/elements/TableEntrada'
import Snackbar from '../components/elements/Snackbar'
import Directions from '@/assets/addresslist.json'
export default {
    props:['titleSection','datos','District','countrys','estadoCivil','bancos','scols','Options','showEstadoCivil','costelacion','type','idCard'],
     components:{
        UploadFile,
        UploadFileNoObligatorio,
        ButtonLinkDownload,
        TableCapitalEfectivo,
        TableTipoPension,
        TableEntrada,
        Snackbar
    },
    data(){
        return{
            modal_date:false,
            casada:false,
            divorcia:false,
            siTraha:false,
            noTraha:false,
            adres:Directions,
            tablePension:'',
            tableEntrada:'',
            componentTableCapitalEfectivo:'',
            dataSnackbar:{
                timeout:5000,
                color:'success',
                estado:true,
                active:false,
                text:'',
                icon:'' 
            },
            downloadUrlEspecialista: `${process.env.BASE_URL}pdf/Cartapadokterdicasofspecialista.pdf`,
            nameEspecialista:"Cartapadokterdicasofspecialista.pdf",
            downloadUrlDeclaracion: `${process.env.BASE_URL}pdf/DeclaracionCAA.pdf`,
            nameDeclaracion:"DeclaracionCAA.pdf",
            downloadUrlRegistration:`${process.env.BASE_URL}pdf/RegistracionOPC.pdf`,
            nameRegistration:'RegistracionOPC.pdf',
            downloadUrlAlimentacion:`${process.env.BASE_URL}pdf/Solicitudpaalimentacion.pdf`,
            nameAlimentacion:'Solicitudpaalimentacion.pdf',
            overlayFile:false,
              rul: {
                required: value => !!value ||  'E veld aki ta obligatorio',
                email: value => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/.test(value) || 'E email adres no ta den un formato corecto',
                selectReq: value => !!value || 'Por fabor schohe un opcion',
                min6: v => (v || '').length >= 6 || 'Introducir minimo 6 caracteres',
                max6: v => (v || '').length <= 12 || 'Introducir máximo 12 caracteres',
               phone: value => /^[0-9.*()-_#]{0,16}$/.test(value) || 'phone format incorrect'
            },
        }
    },
    methods:{
        validarPension(type,index){
            this.$emit('pensionRestart',type,index)
        },
        validarEntrada(type,index){
            this.$emit('entradaRestart',type,index)
        },
         // funcion para habilitar campos date segun opcion
        changeEstadoCivil(value){
            this.casada = false
            this.divorcia = false
            if(value === '57e6963e-e6e2-49f5-b1fb-5a242a74b938'){
                this.casada = true
            }else if(value === '3d6d56b0-0311-4b66-9068-4e0ea3e11cc4'){
                this.divorcia = true
            }else{
                return
            }
        },
        // validate if user traha
        validateToTraha(value){
            this.noTraha = false,this.siTraha = false
            if(value === 'Si'){
               this.siTraha = true
               this.resetFieldsToTraha()
            }else{
                this.noTraha = true
                this.resetFieldsToTraha()
            }
        },
         // reset fields para validateToTraha = Si
        resetFieldsToTraha(){
             this.datos.datonan.dispuestoPaTraha     = '' 
             this.datos.datonan.nomberDiCompania     = ''
             this.datos.datonan.salarioBruto         = ''
             this.datos.datonan.tinProblemaSalud     = ''
             this.datos.datonan.tinProblemaAdiccion  = ''
             this.datos.datonan.siguiendoTratamento  = '' 
             this.datos.datonan.taBayScolDenDia      = ''
             this.datos.datonan.nomberDiScol         = ''
             this.datos.datonan.tinCuidoMandatorioZorgplicht        = ''
             this.datos.datonan.tinCuidoMandatorio   = ''
             this.datos.datonan.tinNecesidadDiGuiaTrahado = ''
             this.datos.datonan.gastonanDiTransportePaLuna = ''
             this.datos.datonan.yiuTaBayCasa         = '' 
        },
        //function mounted component table pension and table entrada
         mountedComponentOtroEntrada(value){
            if(value === 'Si'){
                this.tablePension = 'TableTipoPension'
                this.tableEntrada = 'TableEntrada'
            }else{
                this.tablePension = ''
                this.tableEntrada = ''
            }
        },
        // 5 capital efectivo
        // funcion para cargar componente de tabla de informacion
        mountedComponentCapitalEfectivo(value){
            if(value === 'Si'){
                this.componentTableCapitalEfectivo = 'TableCapitalEfectivo'
            }else{
                this.componentTableCapitalEfectivo = ''
            }
        },

        activeOverlay(){
            this.overlayFile = true
        },
        desactiveOverlay(){
            this.overlayFile = false
        },
         errorFile(message){
            this.dataSnackbar.text =  message
            this.dataSnackbar.color = 'error'
            this.dataSnackbar.icon = 'mdi-alert-circle'
            this.dataSnackbar.active = true
             setTimeout(()=>{
                this.dataSnackbar.active = false
            },3000)
        },
         successFile(nameFile){
            this.dataSnackbar.text = 'Je bestand '+nameFile+' is succesvol geüpload'
            this.dataSnackbar.color = 'success'
            this.dataSnackbar.icon = 'mdi-check'
            this.dataSnackbar.active = true
             setTimeout(()=>{
                this.dataSnackbar.active = false
            },3000)
        },
        asignedIdRequestFile(type,id){
            switch(type){
                case 201:
                    this.datos.datonan.idPersonummer = id
                break;
                case 301:
                    this.datos.datonan.idCartaDiBanco = id
                break;
                case 101:
                    this.datos.datonan.idPayslip1 = id
                break;
                case 102:
                    this.datos.datonan.idPayslip2 = id
                break;
                case 103:
                    this.datos.datonan.idPayslip3 = id
                break;
                case 1001:
                    this.datos.datonan.idCartaDiTrabaho = id
                break;
                case 601:
                    this.datos.datonan.idCartaDoctor = id
                 break;
                case 801:
                    this.datos.datonan.idCartaOPC = id
                 break;
                case 501:
                    this.alimentacion.idFormularioDiDirectieVoogdijraad = id
                 break;
                case 402:
                    this.datos.datonan.idCartaPareha = id
                 break;
                 case 10002:
                    this.datos.datonan.idCartaDidpl = id
                break;
            }
        }
    }
}
</script>

<style lang="scss">

.link-pdf{
       position: relative;
        background: #429cc6;
        text-decoration: none;
        color: #FFF !important;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 500;
        max-width: 100%;
        padding-left: 130px;
        padding-right: 130px;
        padding-top: 13px;
        padding-bottom: 12px;
        text-align: center;
        height: 56px;
        border-radius: 5px;
        outline: none;
        &:hover{
           background: #ff653a;
           box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            
        }
        img{
            width: 25px;
            position: absolute;
            left: 20px;
        }
        @media(max-width:420px){
            font-size: 13px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
</style>
import Vue from 'vue'
import Router from 'vue-router'
// import AppContentPlaceholder from "../components/AppContentPlaceholder"
import AppContentMainMenu from "../components/AppContentMainMenu"
import AppContentChangesMenu from "../components/AppContentChangesMenu"
import AppContentDownloadableFormsMenu from "../components/AppContentDownloadableFormsMenu"

import AppLayoutFormNewBijstand from "../views/AppLayoutFormNewBijstand"
import AppLayoutFormPagoDaAumento from '../views/AppLayoutFormPagoDaAumento.vue'


Vue.use(Router)

export default new Router({
  mode:'history',
  routes: [ 
    {
        path:"/",
        name: "AppContentMainMenu",
        component: AppContentMainMenu 
    },
    {
        path: "/forms/changes",
        name:"AppContentChangesMenu",
        component: AppContentChangesMenu
    },
    {
        path:"/forms/changes/success/:requestNumber",
        name:"AppLayoutSuccessForm",
        component: ()=> import('../views/AppLayoutSuccesFormDiCambio.vue')
    },
    {
        path: "/forms/downloadable",
        name: "AppContentDownloadableFormsMenu",
        component: AppContentDownloadableFormsMenu
    },

    {
        path: "/forms/bijstandnew",
        name: "AppLayoutFormNewBijstand",
        component: AppLayoutFormNewBijstand
    },

    {
        path: "/forms/pagoDaAumento",
        name: "AppLayoutFormPagoDaAumento",
        component: AppLayoutFormPagoDaAumento
    },
    {
        path:"/forms/success/:requestNumber",
        name:"AppLayoutSuccess",
        component: ()=> import('../views/AppLayoutSucces.vue')
    },
    {
        path:"/admin/",
        name:'admin',
        component: ()=> import('../views/admin/AdminLogin.vue'),
        meta:{
            layout:'login-layout'
        }
    },
    {
        path:"/admin/dashboard",
        name:'adminDashboard',
        component: ()=> import('../views/admin/AdminDashboard.vue'),
        meta:{
            layout:'dashboard-layout'
        }
    },
    {
        path:"/admin/paBijstand",
        name:"adminPaBijstand",
        component: ()=> import('../views/admin/AdminAplicacionDiBijstand.vue'),
        meta:{
            layout:'dashboard-layout'
        }
    },
    {
        path:"/admin/paAumento",
        name:"adminPaAumento",
        component: ()=> import('../views/admin/AdminPeticionPaAumento.vue'),
        meta:{
            layout:'dashboard-layout'
        }
    },
    {
        path:"/admin/formDiCambio",
        name:"adminFormDiCambio",
        component: ()=> import('../views/admin/AdminFormDiCambio.vue'),
        meta:{
            layout:'dashboard-layout'
        }
    },
    {
        path:"/admin/formDiCambioDetails/:id",
        name:"adminFormDiCambioDetails",
        component: ()=> import('../views/admin/AdminFormDiCambioDetails.vue'),
        meta:{
            layout:'dashboard-layout'
        }
    }
  ]
})

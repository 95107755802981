<template>
  <v-container ref="printMe" >

        <Snackbar
        :datos="dataSnackbar"
        ></Snackbar>

        <v-overlay :value="overlay">
            <v-progress-circular
            indeterminate
            color="info"
            ></v-progress-circular>
            <br>
        </v-overlay>


        <!-- modal confirmation -->
        <ModalConfirmation
         :modal="modal_confirmation"
         :title="title_modal"
         :msjSuccess="messageSuccess"
         :msjError="messageError"
         :error="ocurrioError"
         :success="Success"
         @closeModal="modalClose"
        ></ModalConfirmation>

        <!-- modal required files -->
        <Modal-Files 
        :modalFiles="modal_files"
        :Files="requiredFiles"
        :FilesPareha="requiredFilesPareha"
        :FilesChildrens="requiredFilesChildren"
        @close="closeModalFiles"
        ></Modal-Files>

        <!-- modal error submit void fields -->
        <Modal-Error
        :modalError="modal_error"
        @closeModalError="closeModalerror"
        ></Modal-Error>

    <!-- <v-form> -->
    <v-form ref="form"  lazy-validation>
      <v-row >
           <AppContentNoteRequiredFields />
          <v-col cols="10" class="mx-auto">
              <span class="label-form">Motibo di Peticion pa aumento:</span>
              <AutocompleteOptions 
               :info="peticion_pa_aumento"  
               @showForm="habilityForm"
               />
          </v-col>      
       </v-row>
      <v-container class="p-0 container-data" v-show="formDatos">
           <!-- contelacion di famia -->
            <v-row class="contenedor-peticion">
                <span class="container-title">1. Constelacion di famia</span>
                <v-col cols="12">
                    <p>Pa constelacion di e famia scohe un di e siguiente opcionnan:</p>
                    <v-radio-group v-model="datos.ConstelacionDiFamia.constelacionDiFamia">

                        <v-radio label="Mi ta un persona soltero/soltera" value="Mi ta un persona soltero/soltera" @click="radioEvent(datos.ConstelacionDiFamia.constelacionDiFamia)"></v-radio>
                        <v-radio label="Mi ta un persona soltero/soltera cu yiu" value="Mi ta un persona soltero/soltera cu yiu" @click="radioEvent(datos.ConstelacionDiFamia.constelacionDiFamia)"></v-radio>
                        <v-radio label="Mi ta un pareha (casa) sin yiu" value="Mi ta un pareha (casa) sin yiu" @click="radioEvent(datos.ConstelacionDiFamia.constelacionDiFamia)"></v-radio>
                        <v-radio label="Mi ta un pareha (casa) cu yiu" value="Mi ta un pareha (casa) cu yiu" @click="radioEvent(datos.ConstelacionDiFamia.constelacionDiFamia)"></v-radio>
                     </v-radio-group >


                     <p v-show="fam1">Indica e cantidad di yiu cu tin den cuido:</p>
                     <span class="label-form" v-show="fam1">Cantidad di yiu:</span>
                     <v-autocomplete 
                     :items="numChildrens"
                      append-icon="mdi-star" 
                      outlined
                    v-model="datos.ConstelacionDiFamia.cantidadDiYiu" 
                     v-show="fam1"
                     @change="addFieldChildrens(datos.ConstelacionDiFamia.cantidadDiYiu)"
                     ></v-autocomplete>

                 </v-col>
            </v-row>

             <!-- aplicante -->
            <AppSectionForm
            titleSection="APLICANTE"
            :showEstadoCivil="true"
            :datos="datos.aplicante"
            :costelacion="Costelacion"
            :countrys="paises" 
            :District="Districto" 
            :estadoCivil="EstadoCivil"
            :bancos="Banks"
            :scols="AllScols"
            :Options="OptionsMandatorio"
            type="daAumento"
            idCard="aplicante"
            ></AppSectionForm>

            <!-- pareha -->
            <AppSectionForm
            v-if="showPareha"
            titleSection="PAREHA"
            :showEstadoCivil="false"
            :datos="datos.pareha"
            :costelacion="Costelacion"
            :countrys="paises" 
            :District="Districto" 
            :estadoCivil="EstadoCivil"
            :bancos="Banks"
            :scols="AllScols"
            idCard="pareha"
            :Options="OptionsMandatorio"
            @pensionRestart="restartPension"
            @entradaRestart="restartEntrada"
            ></AppSectionForm>

            <!-- component for childrens -->
            <AppSectionChildrens 
              :info="datos.childrens" 
              :District="Districto" 
              :countrys="paises" 
              :bancos="Banks"
              :estadoCivil="EstadoCivil"
              :scols="AllScols"
              :Options="OptionsMandatorio"
              @childrenPensionRestart="restartPensionChildrens"
              @childrenEntradaRestart="restartEntradaChildrens"
            ></AppSectionChildrens>


            
            <!-- 6. Vivienda -->
            <FormVivienda
            :datos="datos.vivienda"
            :showHuur="showHuurCas"
            :showContainer="showHende"
            :showContainer2="showFamiaTaBiba"
            :showContainer3="showSercaDiKien"
            :showDiKien="showForDiKien"
            @validateVivienda="validateFamia"
            @validateHuur="validateFamiaHuurCas"
            @validateHende="validateFamiaHende"
            ></FormVivienda>



                <!-- 7. Gastonan mensual -->
            <v-row class="contenedor-peticion pb-5">
                <span class="container-title">7. Gastonan mensual</span>
                <v-col cols="11" class="mx-auto">
                     <v-textarea
                        prepend-inner-icon="mdi-comment"
                        class="mx-2"
                        outlined
                        label="Con bo a mantene bo mes e ultimo 3 lunanan aki?"
                        v-model="datos.gastonanMensual.manteneUltimoMeses"
                        :rules="[rul.required]"
                        ></v-textarea>
                </v-col>
                <v-col cols="11" class="mx-auto">
                     <div class="note2">
                        <p>Yena tur Gastonan mensual den e seccion aki. Gastonan di transporte ta referi na  
                         un medio di transporte cu no ta propio, manera bus of autobus.</p>
                    </div>
                </v-col>

                <TableGastonan 
                 :Data="datos.gastonanMensual.gastonanMensual"
                ></TableGastonan>


                   
            </v-row>

               <!-- 8 - instrucciones importantes -->
            <v-row class="contenedor-peticion pb-5">
                <span class="container-title">8. Instrucionnan importante </span>
                 <v-col cols="11" class="mx-auto">
                    <div class="note2">
                            <p>Lesa instructionnan importante</p>
                        </div>
                        <p>Tene bon cuenta cu e siguiente pasobra e ta <span>obligatorio:</span></p>
                        <ol class="style01">
                            <li> Mester <span>Upload</span> un Identificacion (ID) VALIDO </li>
                            <li>Esaki por ta solamente un Paspoort, Rijbewijs of Cedula.</li>
                        </ol>
                </v-col>
                <v-col cols="11" class="mx-auto">
                    <v-row class="contenedor-peticion">
                        <span class="container-title">Upload ID of paspoort</span>

                        <UploadFile 
                            name="Upload file" 
                            :size=30 
                            format="PDF, JPG and PNG"
                            fileTypeEnum='APLICANTE'
                            :fileSection=401
                            :numChild=0
                            :idFile="datos.important.idPassport"
                            @active="activeOverlay"
                            @desactive="desactiveOverlay"
                            @asigned="asignedIdRequestFile"
                            @error="errorFile"
                            @success="successFile"
                        ></UploadFile>


                    </v-row>
                </v-col>
            </v-row>

            <!-- Declaracion -->
             <Declaracion @ValidarCheck="validarCheck" />
             
            <v-row>
           
                <v-col cols="12">
                    <button class="btn-submit" v-show="btn_submit" @click="submitForm($event)">Aplica pa Aumento</button>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-center">
                    <span class="text-obligatorio">* Obligatorio pa yena</span>
                </v-col>
            </v-row>
            <!-- <v-row>
                <v-btn @click="validateDPL()">probar</v-btn>
            </v-row> -->
      </v-container>
    </v-form>

    <!-- </v-form> -->
      
  </v-container>
</template>
<script>
//importacion de elementos
import axios from 'axios';
import AutocompleteOptions from '../elements/AutocompleteOptions'
import Declaracion from '../elements/Declaracion'
import AppContentNoteRequiredFields from '../AppContentNoteRequiredFields'
import AppSectionChildrens from '../AppSectionChildren'
import AppSectionForm from '../AppSectionForm'
import FormVivienda from '../elements/FormVivienda'
import UploadFile from '../elements/UploadFile'
import TableGastonan from '../elements/TableGastonanMensual'
import ModalConfirmation from '../elements/ModalConfirmation'
import ModalFiles from '../elements/ModalFileRequired.vue'
import ModalError from '../elements/ModalErrorSend.vue'
import Snackbar from '../elements/Snackbar'
// importacion de Json
import Countrys from '@/assets/countries.json'
import Scol from '@/assets/scol.json'
import { jsPDF } from "jspdf";
export default {
    components:{
        AutocompleteOptions,
        AppContentNoteRequiredFields,
        AppSectionChildrens,
        AppSectionForm,
        FormVivienda,
        UploadFile,
        TableGastonan,
        Declaracion,
        ModalConfirmation,
        ModalFiles,
        ModalError,
        Snackbar
    },
    data(){
        return{
            output:null,
            publicPath: process.env.BASE_URL,
            option:null,
            name_options_nan:'',
            validateType:null,
            radioGroup:'',
            overlay:false,
            modal_confirmation:false,
            title_modal:'',
            requestNumber:'',
            ocurrioError:false,
            Success:false,
            messageError:'',
            messageSuccess:'',
            modal_files:false,
            requiredFiles:[],
            requiredFilesPareha:[],
            requiredFilesChildren:[],
            modal_error:false,
            datos:{
                type:2,
                peticion:"",
                key:'',
                pdfFile:'',
                ConstelacionDiFamia:{
                    constelacionDiFamia :'',
                    miTaUnPareha :'',
                    cantidadDiYiu:0,
                    otroTipo:'',
                },
                aplicante:{
                        datonan:{
                            fam: "",
                            nan: "",
                            adres: "",
                            nummerAdres:"",
                            district: "",
                            fechaDiNacimento: "",
                            lugarDiNacemento: "",
                            nacionalidad: "",
                            numberDiTelefon: "",
                            email: "",
                            sexo: "",
                            estadoCivil:"",
                            fechaDivorcia:"",
                            fechaCasa:"",
                            numberDiCedula:"",
                            idPersonummer:"",
                            tinCuentaBancoLocal:"",
                            nomberDiBanco:"",
                            numberDiCuentaCorriente:"",
                            idCartaDiBanco:"",
                            personataTraha:"",
                            nomberDiCompania:"",
                            idPayslip1:"",
                            idPayslip2:"",
                            idPayslip3:"",
                            idCartaDiTrabaho:"",
                            salarioBruto:"",
                            ultimoTraha:"",
                            motiboNoTrahaMas:"",
                            dispuestoPaTraha:"",
                            numberDiRegistracionDiDPL:"",
                            tinProblemaSalud:"",
                            tinProblemaAdiccion:"",
                            siguiendoTratamento:"",
                            idCartaDoctor:"",
                            taBayScolDenDia:"",
                            nomberDiScol:"",
                            tinCuidoMandatorioZorgplicht:"",
                            tinCuidoMandatorio:"",
                            tinNecesidadDiGuiaTrahado:"",
                            idCartaOPC:"",
                            gastonanDiTransportePaLuna:"",
                            idCartaPareha:"",
                            yiuTaBayCasa:"",
                        },
                        otroEntrada:{
                            tinOtraEntrada:"",
                             tipoDiPension:[
                                {tipoDiPensioen:"AOV/AWW/Aruba",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Elmar",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Gobierno(APFA)",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di WEB",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Astec",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Setar",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Meta Corporation",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di trabou",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Aseguro",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di exterior",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"}
                            ],
                            entradaNan:[
                                {entrada:"Placa di alimentacion",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur kamber na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur cas na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur apartament na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur tereno na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Side-jobs",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Yudansa en efectivo of producto/mercancia",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Otro entradanan",desdeKiFecha:"",sumaDenAwg:0,range:""}
                            ]
                        },
                        alimentacion:{
                            taDeseaIntermediacionDiDirectieVoogdijraad:"",
                            idFormularioDiDirectieVoogdijraad:"",
                        },
                        CapitalEfectivo:{
                            capitalEfectivo:"",
                            capital:[
                                {nomberDiBanco:"",numberDiCuenta:"",sumaTotal:0}
                            ]
                        },
                },
                pareha:{
                        datonan:{
                           fam: "",
                            nan: "",
                            adres: "",
                            nummerAdres:"",
                            district: "",
                            fechaDiNacimento: "",
                            lugarDiNacemento: "",
                            nacionalidad: "",
                            numberDiTelefon: "",
                            email: "",
                            sexo: "",
                            estadoCivil:"",
                            fechaDivorcia:"",
                            fechaCasa:"",
                            numberDiCedula:"",
                            idPersonummer:"",
                            tinCuentaBancoLocal:"",
                            nomberDiBanco:"",
                            numberDiCuentaCorriente:"",
                            idCartaDiBanco:"",
                            personataTraha:"",
                            nomberDiCompania:"",
                            idPayslip1:"",
                            idPayslip2:"",
                            idPayslip3:"",
                            idCartaDiTrabaho:"",
                            salarioBruto:"",
                            ultimoTraha:"",
                            motiboNoTrahaMas:"",
                            dispuestoPaTraha:"",
                            numberDiRegistracionDiDPL:"",
                            tinProblemaSalud:"",
                            tinProblemaAdiccion:"",
                            siguiendoTratamento:"",
                            idCartaDoctor:"",
                            taBayScolDenDia:"",
                            nomberDiScol:"",
                            tinCuidoMandatorioZorgplicht:"",
                            tinCuidoMandatorio:"",
                            tinNecesidadDiGuiaTrahado:"",
                            idCartaOPC:"",
                            gastonanDiTransportePaLuna:"",
                            idCartaPareha:"",
                        },
                        otroEntrada:{
                            tinOtraEntrada:"",
                            tipoDiPension:[
                                {tipoDiPensioen:"AOV/AWW/Aruba",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Elmar",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Gobierno(APFA)",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di WEB",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Astec",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Setar",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Meta Corporation",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di trabou",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Aseguro",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di exterior",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"}
                            ],
                            entradaNan:[
                                {entrada:"Placa di alimentacion",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur kamber na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur cas na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur apartament na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur tereno na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Side-jobs",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Yudansa en efectivo of producto/mercancia",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Otro entradanan",desdeKiFecha:"",sumaDenAwg:0,range:""}
                            ]
                        },
                        alimentacion:{
                            taDeseaIntermediacionDiDirectieVoogdijraad:"",
                            idFormularioDiDirectieVoogdijraad:"",
                        },
                        CapitalEfectivo:{
                            capitalEfectivo:"",
                            capital:[
                                {nomberDiBanco:"",numberDiCuenta:"",sumaTotal:0}
                            ]
                        },
                },
                childrens:[],
                vivienda:{
                    famiaTinCasPropio:"",
                    famiaTaHuurCas:"",
                    forDiKien:"",
                    sercaDiKien:"",
                    famiaTaBibandoCercaOtroHende:"",
                    undaFamiaTaBiba:""
                },
                gastonanMensual:{
                    manteneUltimoMeses:"",
                    gastonanMensual:[
                        {gasto:"Cuminda",suma:0},
                        {gasto:"WEB",suma:0},
                        {gasto:"ELMAR",suma:0},
                        {gasto:"SETAR(Celular/Cable/Telefon)",suma:0},
                        {gasto:"Gastonan di transporte",suma:0},
                        {gasto:"Gastonan di vivienda",suma:0},
                        {gasto:"Otro(nan)",suma:0},
                    ]
                },
                
                important:{
                    idPassport:""
                },
            },
           
            fam1:false,
            fam2:false,
            formDatos:false,
            modal_date:false,
            paises:Countrys,
            AllScols:Scol,
            numChildrens:[],
            Costelacion:false,
            showPareha:false,
            showHuurCas:false,
            showHende:false,
            showFamiaTaBiba:false,
            showSercaDiKien:false,
            showForDiKien:false,
            peticion_nobo:[
                {"uuid":"a3df489d-155c-42a5-993c-d317d938be04","name":"A perde trabou","value":'A perde trabou'},
                {"uuid":"ca17dc5b-0f8b-412d-b9ef-8e9bcbacd363","name":"Tin menos entrada","value":'Tin menos entrada'},
                {"uuid":"96b4491a-541f-4316-93e3-011102888e93","name":"Motibo di salud","value":'Motibo di salud'},
                {"uuid":"86dce0f8-7f27-474b-b6af-96c14678f8b0","name":"Otro","value":'Otro'}
            ],
            peticion_pa_aumento:[
                 {"uuid":"0a604409-5cb5-4790-8701-fe7ba98f36d4","name":"No tin entrada mas","value":"No tin entrada mas"},
                 {"uuid":"dba635b5-6055-4a67-a258-1ccef80fc2b0","name":"No ta ricibi placa di alimentacion mas","value":"No ta recibi placa di alimentacion mas"},
                 {"uuid":"0458228d-a279-4897-9ebc-80adee0f96d2","name":"Gastonan excepcional relaciona cu un malesa(dieetkosten of otro)","value":"Gastonan excepcional relaciona cu un malesa(dieetkosten of otro)"},
                 {"uuid":"2e8725fb-3bb6-4c25-8c3a-a66a6ce50e15","name":"Cambio den grandura di famia","value":"Cambio den grandura di famia"},
                 {"uuid":"86dce0f8-7f27-474b-b6af-96c14678f8b0","name":"Otro","value":"Otro"}
            ],
            notificacion_di_cambio:[
                {"name":"Cambio di adres","value":"Cambio di adres"},
                {"name":"Cambio di fam","value":"Cambio di fam"},
                {"name":"Estado civil","value":"Estado civil"},
                {"name":"A haya trabou","value":"A haya trabou"},
                {"name":"Stop bijstand","value":"Stop bijstand"},
                {"name":"Number di telefon of celular","value":"Number di telefon of celular"},
                {"name":"Kier apodera un otra persona pa cobrar bijstand","value":"Kier apodera un otra persona pa cobrar bijstand"},
                {"name":"Otro","value":"Otro"}
            ],
            
            // 2 .- Datonan apicante
            // estado civil
            valorEstadoCivil:'',
            casada:false,
            casadaPareha:false,
            divorcia:false,
            divorciaPareha:false,
            // ta traha
            taTraha:'',
            siTraha:false,
            siTrahaPareha:false,
            noTraha:false,
            noTrahaPareha:false,
            
            
            EstadoCivil:[
                {"uuid":"b1c817dd-f15f-4ee0-ba28-cc6e0e254d1a","name":"Soltero/Soltera","value":"Soltero/Soltera"},
                {"uuid":"57e6963e-e6e2-49f5-b1fb-5a242a74b938","name":"Casa","value":"Casa"},
                {"uuid":"3d6d56b0-0311-4b66-9068-4e0ea3e11cc4","name":"Divorcia","value":"Divorcia"},
                {"uuid":"d73b31ea-65d7-4b24-8398-6b97d19f65cc","name":"Viudo/viuda","value":"Viudo/viuda"},
            ],
            Districto:[
                {"uuid":"d6ecb6bd-b573-4ca1-9b87-f581206e5779","name":"Oranjestad","value":"Oranjestad"},
                {"uuid":"948073ea-cd60-46c1-985d-48612b9d8e0a","name":"Noord","value":"Noord"},
                {"uuid":"1e9b3912-0404-4332-a5b3-8597f356fb25","name":"Paradera","value":"Paradera"},
                {"uuid":"326e7fec-f07f-4d95-adca-38d3300b26cd","name":"Santa Cruz","value":"Santa Cruz"},
                {"uuid":"225e66c2-c708-48c6-8893-5f9ba9343fea","name":"Savaneta","value":"Savaneta"},
                {"uuid":"0e3cf4c7-537f-489c-9ffb-ff7c7cef5864","name":"San Nicolas","value":"San Nicolas"}
            ],
            
            mandatorio:'',
            OptionsMandatorio:[
                {"uuid":"f8ce56d6-c44a-49ed-98ef-8332334f7f2b","name":"Mayornan","value":"Mayornan"},
                {"uuid":"6e9138ac-d679-4617-b751-19a53e495fcd","name":"Casa","value":"Casa"},
                {"uuid":"2ed2a289-c6ab-419f-a9fb-b437548b758d","name":"Yiu(nan)","value":"Yiu(nan)"}
            ],
            cuentaBanco:'',
            Banks:[
                {"uuid":"845F45A6-9EF4-443F-9799-BC8908A2751E","name":"Aruba Bank","value":"Aruba Bank"},
                {"uuid":"508BD49C-AA25-47C1-834F-408675C69BC5","name":"Banco di Caribe","value":"Banco di Caribe"},
                {"uuid":"4B497D42-033E-43FC-B220-6C9D4C21B8B9","name":"CIBC First","value":"CIBC First"},
                {"uuid":"A3720724-4C81-4A87-8864-8815307D9F7B","name":"RBC Bank","value":"RBC Bank"},
                {"uuid":"F7B13BDD-4965-4050-9445-7D1D4E179B47","name":"Caribbean Mercantile Bank","value":"Caribbean Mercantile Bank"}
            ],
            //3 - Otro Entrada
            tablePension:'',
            tableEntrada:'',
            tablePensionPareha:'',
            tableEntradaPareha:'',
            dataTableEntrada:[
                {"name":"Placa di alimentacion"},
                {"name":"Huur kamber na un otro hende"},
                {"name":"Huur cas na un otro hende"},
                {"name":"Huur apartament na un otro hende"},
                {"name":"Huur tereno na un otro hende"}
            ],
            //5- capital efectivo
            capitalEfectivo:'',
            componentTableCapitalEfectivo:'',
            componentTableCapitalEfectivoPareha:'',
            btn_submit:false,
            dataSnackbar:{
                timeout:5000,
                color:'success',
                estado:true,
                active:false,
                text:'',
                icon:'' 
            },
            estado_civil:'',
             rul: {
                required: value => !!value ||  'E veld aki ta obligatorio',
                email: value => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/.test(value) || 'Email incorrect',
                selectReq: value => !!value || 'Debe seleccionar una opcion',
                min6: v => (v || '').length >= 6 || 'Introducir minimo 6 caracteres',
            },            
        }
    },
    mounted(){
        this.getRequestKey()
        this.chargerChildren()     
    },
    computed:{
        // dateMaxim(){
        //     let date = new Date();
        //     let day = date.getDate();
        //     let month = date.getMonth();
        //     month = month+1;
        //     let year = date.getFullYear();
        //         day  = ('0' + day).slice(-2);
        //         month   = ('0' + month).slice(-2);
        //         year = year-18;
        //         return  `${year}-${month}-${day}`; 
        // }
    },
    methods: {

        restartPension(type,index){
            if(type == 'APLICANTE'){
                this.datos.aplicante.otroEntrada.tipoDiPension[index].sumaDenAwg = 0
            }else{
                this.datos.pareha.otroEntrada.tipoDiPension[index].sumaDenAwg = 0
            }
        },
        restartEntrada(type,index){
            if(type == 'APLICANTE'){
                this.datos.aplicante.otroEntrada.entradaNan[index].sumaDenAwg = 0
            }else{
                this.datos.pareha.otroEntrada.entradaNan[index].sumaDenAwg = 0
            }
        },
        restartPensionChildrens(index,num){
            this.datos.childrens[num].otroEntrada.tipoDiPension[index].sumaDenAwg = 0
        },
        restartEntradaChildrens(index,num){
            this.datos.childrens[num].otroEntrada.entradaNan[index].sumaDenAwg = 0
        },
        createExportPDF(){
                this.$html2canvas(this.$refs.printMe, {
                DPI: 300, // Exportar definición PDF
                }).then((canvas) => {
                    var contentWidth = canvas.width;
                    var contentHeight = canvas.height;
            
                  // una página PDF muestra la altura de lienzo generada por la página HTML;
                    var pageHeight = contentWidth / 592.28 * 841.89;
                            // No genere la altura de la página HTML del PDF
                    var leftHeight = contentHeight;
                            // Página PDF Desplazamiento
                    var position = 0;
                            // Página HTML generada por el lienzo en el PDF (el tamaño del papel A4 [595.28, 841.89])
                    var imgWidth = 595.28;
                    var imgHeight = 592.28 / contentWidth * contentHeight;
            
                    var pageData = canvas.toDataURL('image/jpeg', 1.0);
                    var pdf = new jsPDF('', 'pt', 'a4',);
            
                            // Hay dos alturas para distinguir, una es la altura real de la página HTML, y generando páginas altura de PDF (841.89)
                            // Cuando el contenido no excede el rango de pantalla PDF PAGE, sin paginación
                    if (leftHeight < pageHeight) {
                        pdf.addImage(pageData, 'JPEG', 5, 0, imgWidth, imgHeight);
                    } else {
                        while (leftHeight > 0) {
                            pdf.addImage(pageData, 'JPEG', 5, position, imgWidth, imgHeight)
                            leftHeight -= pageHeight;
                            position -= 841.89;
                        // Evitar agregar una página en blanco
                            if (leftHeight > 0) {
                                pdf.addPage();
                            }
                        }
                    }
                    //  pdf.save('content.pdf');
                    let file = pdf.output("blob")
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = function(){
                        var base64data = reader.result;
                        this.datos.pdfFile = base64data;
                    }
                   
                })
            },
        // async createPdf(){
        //     const el = this.$refs.printMe;
        //     const options = {
        //         type: 'image/jpeg'
        //     }
        //     this.output = await this.$html2canvas(el, options);
        //     console.log(this.output);
                
        //         // var blob = doc.output('blob');
        //         // var formData = new FormData();
        //         // formData.append('pdf', blob);
        //         // doc.save("a4.pdf");
        // },
        
        chargerChildren(){
            for(let i=1;i<=20;i++){
                this.numChildrens.push(i)
            }
        },
        addFieldChildrens(numChildren){
            this.datos.childrens = []
            for(let i=1;i<=numChildren;i++){
                this.datos.childrens.push({
                        datonan:{
                            idCard:`dpl-${i}`,
                            fam: "",
                            nan: "",
                            adres: "",
                            nummerAdres:"",
                            district: "",
                            fechaDiNacimento: "",
                            lugarDiNacemento: "",
                            nacionalidad: "",
                            numberDiTelefon: "",
                            email:"",
                            sexo: "",
                            estadoCivil:"",
                            fechaDivorcia:"",
                            fechaCasa:"",
                            numberDiCedula:"",
                            // idPersonummer:"",
                            tinCuentaBancoLocal:"",
                            nomberDiBanco:"",
                            numberDiCuentaCorriente:"",
                            idCartaDiBanco:"",
                            showTraha:false,
                            personataTraha:"",
                            nomberDiCompania:"",
                            idPayslip1:"",
                            idPayslip2:"",
                            idPayslip3:"",
                            idCartaDiTrabaho:"",
                            salarioBruto:"",
                            ultimoTraha:"",
                            motiboNoTrahaMas:"",
                            dispuestoPaTraha:"",
                            numberDiRegistracionDiDPL:"",
                            tinProblemaSalud:"",
                            tinProblemaAdiccion:"",
                            siguiendoTratamento:"",
                            idCartaDoctor:"",
                            taBayScolDenDia:"",
                            nomberDiScol:"",
                            tinCuidoMandatorioZorgplicht:"",
                            tinCuidoMandatorio:"",
                            tinNecesidadDiGuiaTrahado:"",
                            idCartaOPC:"",
                            gastonanDiTransportePaLuna:"",
                            idCartaPareha:"",
                            yiuTaBayCasa:"",
                        },
                        otroEntrada:{
                            tinOtraEntrada:"",
                            tablePension:'',
                            tableEntrada:'',
                            tipoDiPension:[
                                {tipoDiPensioen:"AOV/AWW/Aruba",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Elmar",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Gobierno(APFA)",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di WEB",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Astec",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Setar",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Meta Corporation",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di trabou",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di Aseguro",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"},
                                {tipoDiPensioen:"Pensioen di exterior",desdeKiFecha:"",sumaDenAwg:0,range:"Pa Luna"}
                            ],
                            entradaNan:[
                                {entrada:"Placa di alimentacion",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur kamber na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur cas na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur apartament na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Huur tereno na un otro hende",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Side-jobs",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Yudansa en efectivo of producto/mercancia",desdeKiFecha:"",sumaDenAwg:0,range:""},
                                {entrada:"Otro entradanan",desdeKiFecha:"",sumaDenAwg:0,range:""}
                            ]
                        },
                        alimentacion:{
                            taDeseaIntermediacionDiDirectieVoogdijraad:"",
                            idFormularioDiDirectieVoogdijraad:"",
                        },
                        CapitalEfectivo:{
                            capitalEfectivo:"",
                            componentTableCapitalEfectivo:'',
                            capital:[
                                {nomberDiBanco:"",numberDiCuenta:"",sumaTotal:0}
                            ]
                        }
                })
            }
        },
        habilityDataScolChildrens(value,index){
            this.datos.ConstelacionDiFamia.childrens[index].showScol = value === 'Yes' ? true : false
        },
         habilityDataTraha(value,index){
            this.datos.ConstelacionDiFamia.childrens[index].showDataTraha = value === 'Yes' ? true : false
        },
        habilityDataTrahaChildren(fecha_nacimiento,index){
                var hoy = new Date();
                var cumpleanos = new Date(fecha_nacimiento);
                var edad = hoy.getFullYear() - cumpleanos.getFullYear();
                var m = hoy.getMonth() - cumpleanos.getMonth();
                if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                    edad--;
                }
                this.datos.childrens[index].showTraha = edad >= 16 ? true : false
        },
        radioEvent(evento){
            switch(evento){
                case'Mi ta un persona soltero/soltera':
                    this.estado_civil = 'soltero'
                    this.fam1       = false
                    this.showPareha = false
                     this.Costelacion = false
                    this.datos.childrens = []
                    this.datos.ConstelacionDiFamia.cantidadDiYiu = 0
                    this.datos.aplicante.datonan.estadoCivil = 'b1c817dd-f15f-4ee0-ba28-cc6e0e254d1a'
                break;
                case'Mi ta un persona soltero/soltera cu yiu':
                    this.estado_civil = 'solteroHijo'
                    this.fam1       = true
                    this.showPareha = false
                    this.Costelacion = true
                    this.datos.aplicante.datonan.estadoCivil = 'b1c817dd-f15f-4ee0-ba28-cc6e0e254d1a'
                break;
                case'Mi ta un pareha (casa) sin yiu':
                    this.estado_civil = 'pareha'
                    this.fam1       = false
                    this.showPareha = true
                    this.Costelacion = false
                    this.datos.childrens = []
                    this.datos.ConstelacionDiFamia.cantidadDiYiu = 0
                     this.datos.aplicante.datonan.estadoCivil = '57e6963e-e6e2-49f5-b1fb-5a242a74b938'
                break;
                case'Mi ta un pareha (casa) cu yiu':
                    this.estado_civil = 'parehaHijo'
                    this.fam1       = true
                    this.showPareha = true
                    this.Costelacion = true
                    this.datos.aplicante.datonan.estadoCivil = '57e6963e-e6e2-49f5-b1fb-5a242a74b938'
                break;
                default:
                break;
            }
        },
        habilityForm(peticion){
            if(this.validateType != 3){
                this.formDatos = true
                this.datos.peticion = peticion
            }else{
                this.formDatos = false
            }
        },

        validateDPL(){

            if(this.estado_civil === 'soltero'){
                if(this.datos.aplicante.datonan.personataTraha === 'No'){
                    if(this.datos.aplicante.datonan.dispuestoPaTraha === 'Si'){
                        if(this.datos.aplicante.datonan.idCartaDidpl !== ''){
                            this.scrollToId('dpl-aplicante');
                        }
                    }
                }
            }
            
            if(this.estado_civil === 'solteroHijo'){
                if(this.datos.aplicante.datonan.personataTraha === 'No'){
                    if(this.datos.aplicante.datonan.dispuestoPaTraha === 'Si'){
                        if(this.datos.aplicante.datonan.idCartaDidpl !== ''){
                            this.scrollToId('dpl-aplicante');
                            return;
                        }
                    }else{
                        for(let i=0;i<=this.datos.childrens.length;i++){
                            if(this.datos.childrens[i].datonan.personataTraha === 'No'){
                                if(this.datos.childrens[i].datonan.dispuestoPaTraha === 'Si'){
                                    if(this.datos.childrens[i].datonan.idCartaDidpl !== ''){
                                        this.scrollToId(`dpl-${i+1}`);
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
                

            }

            if(this.estado_civil === 'pareha'){
                if(this.datos.aplicante.datonan.personataTraha === 'No'){
                    if(this.datos.aplicante.datonan.dispuestoPaTraha === 'Si'){
                        if(this.datos.aplicante.datonan.idCartaDidpl !== ''){
                            this.scrollToId('dpl-aplicante');
                        }
                    }
                }else{
                    if(this.datos.pareha.datonan.personataTraha === 'No'){
                        if(this.datos.pareha.datonan.dispuestoPaTraha === 'Si'){
                            if(this.datos.pareha.datonan.idCartaDidpl !== ''){
                                this.scrollToId('dpl-pareha');
                            }
                        }
                    }
                }
            }

            if(this.estado_civil === 'parehaHijo'){
                if(this.datos.aplicante.datonan.personataTraha === 'No'){
                    if(this.datos.aplicante.datonan.dispuestoPaTraha === 'Si'){
                        if(this.datos.aplicante.datonan.idCartaDidpl !== ''){
                            this.scrollToId('dpl-aplicante');
                        }
                    }
                }else{
                    if(this.datos.pareha.datonan.personataTraha === 'No'){
                        if(this.datos.pareha.datonan.dispuestoPaTraha === 'Si'){
                            if(this.datos.pareha.datonan.idCartaDidpl !== ''){
                                this.scrollToId('dpl-pareha');
                            }
                        }
                    }else{
                        for(let i=0;i<=this.datos.childrens.length;i++){
                            if(this.datos.childrens[i].datonan.personataTraha === 'No'){
                                if(this.datos.childrens[i].datonan.dispuestoPaTraha === 'Si'){
                                    if(this.datos.childrens[i].datonan.idCartaDidpl !== ''){
                                        this.scrollToId(`dpl-${i+1}`);
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }   
        },
        // reset fields para validateToTraha = Si
        resetFieldsToTraha(){
             this.datos.aplicante.datonan.dispuestoPaTraha     = '' 
             this.datos.aplicante.datonan.nomberDiCompania     = ''
             this.datos.aplicante.datonan.salarioBruto         = ''
             this.datos.aplicante.datonan.tinProblemaSalud     = ''
             this.datos.aplicante.datonan.tinProblemaAdiccion  = ''
             this.datos.aplicante.datonan.siguiendoTratamento  = '' 
             this.datos.aplicante.datonan.taBayScolDenDia      = ''
             this.datos.aplicante.datonan.nomberDiScol         = ''
             this.datos.aplicante.datonan.tinCuidoMandatorioZorgplicht        = ''
             this.datos.aplicante.datonan.tinCuidoMandatorio   = ''
             this.datos.aplicante.datonan.tinNecesidadDiGuiaTrahado = ''
             this.datos.aplicante.datonan.gastonanDiTransportePaLuna = ''
             this.datos.aplicante.datonan.yiuTaBayCasa         = '' 
        },
        // function to enable radiobuton fields in family
        validateFamia(value){
           if(value === 'No'){
                this.showHuurCas = true 
            }else{
                this.showHuurCas = false
                this.showFamiaTaBiba = false 
                this.showHende = false
                this.datos.vivienda.famiaTaHuurCas = ''
                this.datos.vivienda.famiaTaBibandoCercaOtroHende = ''
                this.datos.vivienda.undaFamiaTaBiba = ''
            }
        },
        //function to enable radiobuton fields in family
       validateFamiaHuurCas(value){
           if(value === 'No'){ 
               this.showHende = true 
               this.showForDiKien = false
               this.datos.vivienda.forDiKien = ''
            }else{
                this.showForDiKien = true
                this.showFamiaTaBiba = false
                this.showSercaDiKien = false
                this.showHende = false
                this.datos.vivienda.famiaTaBibandoCercaOtroHende = ''
                this.datos.vivienda.undaFamiaTaBiba = ''
                this.datos.vivienda.sercaDiKien = ''
            }
        },
        // function to enable radiobuton fields in family
         validateFamiaHende(value){
           if(value === 'No'){ 
               this.showFamiaTaBiba = true 
               this.showSercaDiKien = false
               this.datos.vivienda.sercaDiKien = ''
            }else{
                this.showSercaDiKien = true
                this.showFamiaTaBiba = false
                this.datos.vivienda.undaFamiaTaBiba = ''
            }
        },
        // 3 Otro Entrada 
        // funcion para cargar componente de tablas de otras entradas 
        // pension y otra entrada
        mountedComponentOtroEntrada(value,user){
            if(value === 'Si'){
                if(user === 'aplicante'){
                    this.tablePension = 'TableTipoPension'
                    this.tableEntrada = 'TableEntrada'
                }else{
                    this.tablePensionPareha = 'TableTipoPension'
                    this.tableEntradaPareha = 'TableEntrada'
                }
               
            }else{
                if(user === 'aplicante'){
                    this.tablePension = ''
                    this.tableEntrada = ''
                }else{
                    this.tablePensionPareha = ''
                    this.tableEntradaPareha = ''
                }
                
            }
        },
        // 5 capital efectivo
        // funcion para cargar componente de tabla de informacion
        mountedComponentCapitalEfectivo(value,user){
            if(value === 'Si'){
                if(user === 'aplicante'){
                    this.componentTableCapitalEfectivo = 'TableCapitalEfectivo'
                }else{
                    this.componentTableCapitalEfectivoPareha = 'TableCapitalEfectivo'
                }
                
            }else{
                if(user === 'aplicante'){
                   this.componentTableCapitalEfectivo = '' 
                }else{
                    this.componentTableCapitalEfectivoPareha = ''
                }
                
            }
        },
        validarCheck(checked){
            this.btn_submit = checked
        },
       
         errorFile(message){
            this.dataSnackbar.text =  message
            this.dataSnackbar.color = 'error'
            this.dataSnackbar.icon = 'mdi-alert-circle'
            this.dataSnackbar.active = true
             setTimeout(()=>{
                this.dataSnackbar.active = false
            },3000)
        },
        
        successFile(nameFile){
            this.dataSnackbar.text = 'Je bestand '+nameFile+' is succesvol geüpload'
            this.dataSnackbar.color = 'success'
            this.dataSnackbar.icon = 'mdi-check'
            this.dataSnackbar.active = true
             setTimeout(()=>{
                this.dataSnackbar.active = false
            },3000)
        },
        async getRequestKey(){
            try{
                const response = await axios.post('/api/requests/requestkey',{});
                localStorage.setItem('requestKey', response.data.requestKey)  
                this.datos.key = response.data.requestKey
            }catch(err){
                console.error(err)
            }
        },
        activeOverlay(){
            this.overlay = true
        },
        desactiveOverlay(){
            this.overlay = false
        },
        asignedIdRequestFile(type,id){
            this.datos.important.idPassport = type === 401 ? id : ''
        },
        modalClose(){
            this.modal_confirmation = false
        },
        closeModalFiles(){
            this.modal_files = false
            this.requiredFiles = []
            this.requiredFilesPareha = []
            this.requiredFilesChildren = []
        },
        closeModalerror(){
            this.modal_error = false;
        },
   
        // validate that all requested files have been uploaded
        validateFile(){
            let error = 0;
            if(this.datos.aplicante.datonan.idPersonummer === ''){
                // this.datos.aplicante.datonan.activeDocPersnummer = true
                error++;
            }
            if(this.datos.aplicante.datonan.tinCuentaBancoLocal === 'Si' && this.datos.aplicante.datonan.idCartaDiBanco === ''){
                // this.requiredFiles.push('Carta di banco');
                error++;
            }
            if(this.datos.aplicante.datonan.personataTraha === 'Si' && this.datos.aplicante.datonan.idCartaDiTrabaho === ''){
                // this.requiredFiles.push('Carta di trabaho');
                error++;
            }
            if(this.showPareha){
                if(this.datos.pareha.datonan.idPersonummer === ''){
                    // this.requiredFilesPareha.push('File personummer');
                    error++;
                }
                if(this.datos.pareha.datonan.tinCuentaBancoLocal === 'Si' && this.datos.pareha.datonan.idCartaDiBanco === ''){
                    // this.requiredFilesPareha.push('Carta di banco');
                    error++;
                }
                if(this.datos.pareha.datonan.personataTraha === 'Si' && this.datos.pareha.datonan.idCartaDiTrabaho === ''){
                    // this.requiredFiles.push('Carta di trabaho');
                    error++;
                }
            }
            if(this.datos.important.idPassport === ''){
                error++;
            }
           let errorChildrens =  this.validateFilesChildrens()
           let errorValidationFiles = error + errorChildrens;
            
            return errorValidationFiles;
        },
        // validate that the children's files are there
        validateFilesChildrens(){
            let error = 0;
            if(this.datos.childrens.length > 0){
                for(let i=0;i < this.datos.childrens.length;i++){
                    
                    if(this.datos.childrens[i].datonan.tinCuentaBancoLocal === 'Si' && this.datos.childrens[i].datonan.idCartaDiBanco === ''){
                        // this.requiredFilesChildren.push('Carta di banco');
                        error++;
                    }
                    if(this.datos.childrens[i].datonan.showTraha){
                        if(this.datos.childrens[i].datonan.personataTraha === 'Si' && this.datos.childrens[i].datonan.idCartaDiTrabaho === ''){
                            // this.requiredFilesChildren.push('Carta di trabaho');
                            error++;
                        }
                    }
                }
            }
            return error;
        },
        
        validateGatonan(){
                const mapeo = this.datos.gastonanMensual.gastonanMensual.map(item =>{
                    if(item.suma === ''){
                        item.suma = 0
                    }
                    return item;
                });
            this.datos.gastonanMensual.gastonanMensual = mapeo
        },

        scrollToId(id){
            console.log(id)
            var access = document.getElementById(id);
            access.scrollIntoView({behavior: 'smooth'}, true);
        },

        async submitForm(e){
            e.preventDefault();
          const error = this.validateFile();
          if (this.$refs.form.validate() && error === 0) {
            this.activeOverlay()
            this.validateGatonan()
            this.createExportPDF();
            this.ocurrioError = false
            this.Success = false
            try{
                const response = await axios.post('/api/peticionbijstand',this.datos);
                if(response.status === 201){
                    this.desactiveOverlay()
                    localStorage.removeItem('requestKey');
                    this.$router.push('/forms/success/'+response.data.requestNumber)
                }
                console.log(response)
            }catch(error){
                this.ocurrioError = true
                console.error(error)
                this.desactiveOverlay()
                this.title_modal = 'Tin un eror cu bo peticion'
                this.messageError = '"Por fabor verifica cu tur informacion ta yena correctamente y purba di nobo."'
                this.modal_confirmation = true
            } 
          }else{
              this.modal_error = true
          }
        }
    },
}
</script>
<style lang="scss">
   .contenedor-peticion{
        border: 1px solid gray;
        border-radius: 5px;
        border-style: dashed;
        position: relative;
        padding-top: 15px;
        margin-bottom: 20px;
        .container-title{
            position: absolute;
            top: -10px;
            left: 12px;
            padding-right: 7px;
            padding-left: 4px;
            background: #fff;
            color: #429cc6;
        }
        .celda{
            margin-bottom: -34px;
        }
        .container-radio{
            position: relative;
            border: 1px solid #429cc6;
            border-radius: 7px;
            margin-top: 12px;
            margin-bottom: 10px;
            .icon-start{
                position: absolute;
                right: 2px;
                top: 5px;
            }
            p{
                font-size: 16px;
                font-weight: 600;
                color: #000 !important;
            }
        }
        .celd-dastonan{
            margin-bottom: -53px;
        }
        .text-icon{
            position: absolute;
            left: 58px;
            top: 20px;
            font-size: 12px;
            font-weight: 400;
            font-style: italic;
            color: #429cc6;
        }
        .text-format{
            font-size: 11px;
            margin-left: 16px;
            color: #429cc6;
            font-weight: 400;
            font-style: italic;
        }
        .icon-important{
            position: absolute;
            left: 5px;
        }
   }
   .text-obligatorio{
       font-weight:300 !important;
   }
   .label-form{
        font-weight: 400;
        font-size: 15px;
        margin-left: 2px;
    }
   .btn-submit{
        background: #429cc6;
        font-size: 15px;
        color: #FFF;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        &:hover{
           background: #ff653a;
           box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            
        }
   }
   .btn-pdf{
       position: relative;
        background: #429cc6;
        text-decoration: none;
        color: #FFF !important;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 500;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        &:hover{
           background: #ff653a;
           box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            
        }
        img{
            position: absolute;
            top: 9px;
            left: 10px;
            width: 30px;
        }
   }
   .v-list-item__content:hover{
       background: #429cc6 !important;
       color: #fff !important;
   }
   .v-label{
       color: #429cc6 !important;
       font-weight: 500 !important;
   }
   .v-icon{
       font-size:16px !important;
   }
   .component-fade-enter-active, .component-fade-leave-active {
        transition: opacity .5s ease;
    }
    .component-fade-enter, .component-fade-leave-to {
        opacity: 0;
    }
    .td{
        width: 33.3%;
        p{
            margin-top: -14px !important;
        }
    }
    .td-2{
        width: 25%;
        p{
            margin-top: -14px !important;
        }
    }
     .container-data{
       width: 100% !important;
   }
    .icon-message{
       font-size: 41px !important;
       margin-top: 14px;
     }
    .icon-close{
       font-size: 30px !important;
     }
   .card-data{
        background: rgb(255, 255, 255);
        box-shadow: rgb(211 211 211) 0px -3px 19px 5px;
        border-radius: 9px;
        padding-top: 68px;
        position: relative;
        .title-data{
            background: #429cc6;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 51px;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            padding-left: 15px;
            p{
                color: #fff;
                font-size: 20px;
                font-weight: 600;
            }
        }
   }
   .container-children{
           border: 1px solid #d3d3d3d3;
            border-radius: 7px;
            padding-bottom: 22px;
            padding-top: 20px;
            position: relative;
            span.titulo{
                position: absolute;
                left: 20px;
                top: -17px;
                background: #fff;
                font-size: 12px;
             }
   }
    .title-modal-confirmation{
       padding: 8px 24px 10px !important;
       height: 50px !important;
       background: #429cc6 !important;
   }
 
    
</style>
<template>
    <v-row >
        <v-col cols="12">
                <button class="btn-submit" @click="$emit('submit',$event)"> {{name}} </button>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props:['name']
}
</script>

<style>

</style>
<template>
    <v-container>
       
        <Snackbar
        :datos="dataSnackbar"
        ></Snackbar>

        <v-overlay :value="overlayFile">
            <v-progress-circular
            indeterminate
            color="info"
            ></v-progress-circular>
            <br>
        </v-overlay>

        <v-row>
            <v-col cols="12" class="text-center"> 
                  <img class="img-icon" src="../../assets/images/icons/icon_address.png" alt="" v-if="icon === 'address'" />
                  <img class="img-icon" src="../../assets/images/icons/icon_lastname.png" alt="" v-if="icon === 'lastname'" >
                  <img class="img-icon" src="../../assets/images/icons/icon_marital.png" alt="" v-if="icon === 'marital'" >
                  <img class="img-icon" src="../../assets/images/icons/icon_job.png" alt="" v-if="icon === 'job'" >
                  <img class="img-icon" src="../../assets/images/icons/icon_cancel.png" alt="" v-if="icon === 'cancel'" >
                   <img class="img-icon" src="../../assets/images/icons/icon_phone.png" alt="" v-if="icon === 'phone'" >
                   <img class="img-icon" src="../../assets/images/icons/icon_authorize.png" alt="" v-if="icon === 'authorize'" >
                   <img class="img-icon" src="../../assets/images/icons/icon_other.png" alt="" v-if="icon === 'other'" >
                  <h3 class="title-form">Formulario pa {{name}} </h3>
            </v-col>
        </v-row>

        <!-- form -->
        <v-row class="contenedor-peticion">
                <span class="container-title">{{subtitle}} </span>

            <AppContentNoteRequiredFields/>

            <!-- <v-col cols="12"  class="celda" v-if="icon === 'address'">
                <span class="label-form" >Fam</span>
                <v-text-field type="text" v-model="info.fam" append-icon="mdi-star" outlined  :rules="[rul.required]"></v-text-field>
            </v-col> -->
            <v-col cols="12"  class="celda" >
                <span class="label-form" v-if="icon !== 'lastname' && icon !== 'address'">Fam(soltera)</span>
                <span v-if="icon === 'lastname'">Fam(Bieu)</span>
                <span class="label-form"   v-if="icon === 'address'">Fam</span>
                <v-text-field type="text"  v-model="info.fam" append-icon="mdi-star" outlined :rules="[rul.required]"></v-text-field>
            </v-col>
            <v-col cols="12"  class="celda" v-if="icon === 'lastname'">
                <span class="label-form" >Fam(nobo)</span>
                <v-text-field type="text"  v-model="info.famNobo" append-icon="mdi-star" outlined :rules="[rul.required]"></v-text-field>
            </v-col>
            <v-col cols="12"  class="celda">
                <span class="label-form" >Prome Nomber(nan)</span>
                <v-text-field type="text" v-model="info.promeNomber" append-icon="mdi-star" outlined :rules="[rul.required]" ></v-text-field>
            </v-col>
            <v-col cols="12"  class="celda" >
                <span class="label-form" v-if="icon === 'phone'">Number di telefon nobo</span>
                <span class="label-form" v-else>Number di telefon </span>
                <v-text-field type="text" v-model="info.numberDiTelefon" append-icon="mdi-star" outlined :rules="[rul.required]" ></v-text-field>
            </v-col>
             <!-- <v-col cols="12"  class="celda" v-if="icon === 'phone'">

                <v-text-field type="text" v-model="info.numberDiTelefonNobo" append-icon="mdi-star" outlined  :rules="[rul.required]"></v-text-field>
            </v-col> -->
            <v-col cols="12"  class="celda">
                <span class="label-form">Email</span>
                <v-text-field type="text" v-model="info.email" append-icon="mdi-star" outlined  :rules="[rul.email]" ></v-text-field>
            </v-col>
            <v-col cols="12"  class="celda" >
                <span class="label-form">Number di Identificacion (ID Nummer)</span>
                <v-text-field type="text" v-model="info.numberDiCedula" append-icon="mdi-star" outlined :rules="[rul.required]" ></v-text-field>
            </v-col>
            <v-col cols="12"  class="celda" >
                <span class="label-form" v-if="icon === 'address'">Adres bieu</span>
                <span class="label-form" v-else>Adres</span>
                <v-row>
                    <v-col cols="8">
                        <v-autocomplete 
                        :items="adres"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        v-model="info.adresId"
                        :rules="[rul.selectReq]"
                        append-icon="mdi-star" 
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="text" append-icon="mdi-star" outlined placeholder="#" v-model="info.numberAdres"></v-text-field>
                    </v-col>
                </v-row>
            </v-col>

              <v-col cols="12"  class="celda" v-if="icon === 'address'">
                <span class="label-form">Adres nobo</span>
                <v-row>
                    <v-col cols="8">
                        <v-autocomplete 
                        :items="adres"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        v-model="info.adresNoboId"
                        :rules="[rul.selectReq]"
                        append-icon="mdi-star" 
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="text" append-icon="mdi-star" outlined placeholder="#" v-model="info.numberAdresNobo"></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <!--<5454>-->
            <v-col cols="12"  class="celda" v-if="icon === 'address'">
                    <span class="label-form">Districto</span>
                    <v-autocomplete  :items="Districto"
                    item-value="value" item-text="name"
                    autocomplete="off"
                    v-model="info.districto"
                    outlined
                    :rules="[rul.selectReq]"
                    append-icon="mdi-star"
                    >
                    </v-autocomplete>
            </v-col>
             <v-col cols="12"  class="celda" v-if="icon === 'marital'" >
                   <span class="label-form">Estado Civil bieu</span>
                    <v-autocomplete   :items="EstadoCivil"
                    item-value="value" item-text="name"
                    autocomplete="off"
                    outlined
                    :rules="[rul.selectReq]"
                    v-model="info.estadoCivilBieuwId"
                    append-icon="mdi-star"
                    >
                    </v-autocomplete>
            </v-col>
             <v-col cols="12"  class="celda" v-if="icon === 'marital'" >
                    <span class="label-form">Estado Civil nobo</span>
                    <v-autocomplete   :items="EstadoCivil"
                    item-value="value" item-text="name"
                    autocomplete="off"
                    outlined
                    v-model="info.estadoCivilNoboId"
                    :rules="[rul.selectReq]"
                    append-icon="mdi-star"
                    >
                    </v-autocomplete>
            </v-col>

            <v-col cols="12"  class="celda" v-if="icon === 'other' || icon === 'authorize' || icon === 'cancel'">
                <span class="label-form" v-if="icon === 'other'">Kico ta bo persona su notificacion di cambio?</span>
                <span class="label-form" v-if="icon === 'authorize'">Motibo di kier apodera un otra persona</span>
                <span class="label-form" v-if="icon === 'cancel'">Motibo dicon kier stop bijstand</span>
                <v-text-field type="text" v-model="info.notes" append-icon="mdi-star" outlined :rules="[rul.required]" ></v-text-field>
            </v-col>

            <v-col cols="12"  class="celda" v-if="icon === 'job'">
                <span class="label-form">Na unda a haya trabou?</span>
                <v-text-field type="text" v-model="info.naUndaHayaTrabou" append-icon="mdi-star" outlined :rules="[rul.required]" ></v-text-field>
            </v-col>
             <v-col cols="12"  class="celda" v-if="icon === 'job'">
                 <span class="label-form">Desde ki dia a haya trabou? (dd-mm-yyyy | Dia / Luna / Aña)</span>
                    <v-dialog ref="dialog" v-model="modal_date" :return-value.sync="info.fechaHayaTrabou" persistent width="290px" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="info.fechaHayaTrabou"  append-icon="mdi-star" readonly v-bind="attrs" v-on="on" outlined="" :rules="[rul.required]"></v-text-field>
                            </template>
                            <v-date-picker v-model="info.fechaHayaTrabou" scrollable >
                                <v-spacer></v-spacer>
                                <v-btn text color="error" @click="modal_date = false ">Annuleren</v-btn>
                                <v-btn  text  color="primary"  @click="$refs.dialog.save(info.fechaHayaTrabou)" >Accepteren</v-btn>
                            </v-date-picker>
                    </v-dialog>
            </v-col>
        </v-row>

        <v-row class="contenedor-peticion" v-if="icon === 'job'">
                <span class="container-title">Upload carta di trabou/contracto di trabou/acuerdo di trabou</span>
                <upload-file-di-cambio
                    name="Upload file" 
                    :size=30
                    format="PDF, JPG and PNG"
                    fileTypeEnum='APLICANTE'
                    :fileSection=1001
                    :numChild=0
                    :idFile="info.fileCartaTrabouContent"
                    @active="activeOverlay"
                    @desactive="desactiveOverlay"
                    @asigned="asignedIdRequestFile"
                    @error="errorFile"
                    @success="successFile"
                ></upload-file-di-cambio>
                 <!-- <UploadFile 
                    name="Upload file" 
                    :size=30
                    format="PDF, JPG and PNG"
                    fileTypeEnum='APLICANTE'
                    :fileSection=1001
                    :numChild=0
                    :idFile="info.fileIdCartaTrabou"
                    @active="activeOverlay"
                    @desactive="desactiveOverlay"
                    @asigned="asignedIdRequestFile"
                    @error="errorFile"
                    @success="successFile"
                ></UploadFile> -->

        </v-row>

        <v-row class="contenedor-peticion" v-if="icon === 'authorize'">
                <span class="container-title">Upload e carta di dokter of hospital</span>
                <upload-file-di-cambio
                    name="Upload file" 
                    :size=30
                    format="PDF, JPG and PNG"
                    fileTypeEnum='APLICANTE'
                    :fileSection=601
                    :numChild=0
                    :idFile="info.fileCartaDoctorContent"
                    @active="activeOverlay"
                    @desactive="desactiveOverlay"
                    @asigned="asignedIdRequestFile"
                    @error="errorFile"
                    @success="successFile"
                ></upload-file-di-cambio>
               <!-- <UploadFile 
                    name="Upload file" 
                    :size=30 
                    format="PDF, JPG and PNG"
                    fileTypeEnum='APLICANTE'
                    :fileSection=601
                    :numChild=0
                    :idFile="info.fileIdCartaDoctor"
                    @active="activeOverlay"
                    @desactive="desactiveOverlay"
                    @asigned="asignedIdRequestFile"
                    @error="errorFile"
                    @success="successFile"
                ></UploadFile>  -->

        </v-row>

        <v-row class="contenedor-peticion" v-if="icon === 'authorize'">
                <span class="container-title">Upload un identificacion valido di e cliente di bijstand</span>
                <upload-file-di-cambio
                    name="Upload file" 
                    :size=30
                    format="PDF, JPG and PNG"
                    fileTypeEnum='APLICANTE'
                    :fileSection=401
                    :numChild=0
                    :idFile="info.fileIdentificationBijstandContent"
                    @active="activeOverlay"
                    @desactive="desactiveOverlay"
                    @asigned="asignedIdRequestFile"
                    @error="errorFile"
                    @success="successFile"
                ></upload-file-di-cambio>
               <!-- <UploadFile 
                    name="Upload file" 
                    :size=30 
                    format="PDF, JPG and PNG"
                    fileTypeEnum='APLICANTE'
                    :fileSection=401
                    :numChild=0
                    :idFile="info.fileIdIdentificationBijstand"
                    @active="activeOverlay"
                    @desactive="desactiveOverlay"
                    @asigned="asignedIdRequestFile"
                    @error="errorFile"
                    @success="successFile"
                ></UploadFile> -->

        </v-row>

          <v-row class="contenedor-peticion" v-if="icon === 'authorize'">
                <span class="container-title">Upload un identificacion valido di e apoderado</span>
                
                <upload-file-di-cambio
                    name="Upload file" 
                    :size=30
                    format="PDF, JPG and PNG"
                    fileTypeEnum='APLICANTE'
                    :fileSection=402
                    :numChild=0
                    :idFile="info.fileIdentificationApoderadoContent"
                    @active="activeOverlay"
                    @desactive="desactiveOverlay"
                    @asigned="asignedIdRequestFile"
                    @error="errorFile"
                    @success="successFile"
                ></upload-file-di-cambio>
                <!-- <UploadFile 
                    name="Upload file" 
                    :size=30 
                    format="PDF, JPG and PNG"
                    fileTypeEnum='APLICANTE'
                    :fileSection=412
                    :numChild=0
                    :idFile="info.fileIdIdentificationApoderado"
                    @active="activeOverlay"
                    @desactive="desactiveOverlay"
                    @asigned="asignedIdRequestFile"
                    @error="errorFile"
                    @success="successFile"
                ></UploadFile> -->

        </v-row>

    </v-container>
</template>

<script>
import AppContentNoteRequiredFields from '@/components/AppContentNoteRequiredFields'
//import UploadFile from '../elements/UploadFile'
import Directions from '@/assets/addresslist.json'
import Snackbar from '../elements/Snackbar'
import UploadFileDiCambio from '../elements/UploadFileDiCambio.vue'
export default {
    name:'FormDiCambio',
    props:['name','icon','showCampos','subtitle','info'],
    components:{
        AppContentNoteRequiredFields,
        //UploadFile,
        Snackbar,
        UploadFileDiCambio
    },
    data(){
        return{
            modal_date:false,
            date_trabou:'',
            base:'',
            Districto:[
                {"name":"Oranjestad","value":"Oranjestad"},
                {"name":"Noord","value":"Noord"},
                {"name":"Paradera","value":"Paradera"},
                {"name":"Santa Cruz","value":"Santa Cruz"},
                {"name":"Savaneta","value":"Savaneta"},
                {"name":"San Nicolas","value":"San Nicolas"}
            ],
            EstadoCivil:[
                {"name":"Soltera(o)","value":"b1c817dd-f15f-4ee0-ba28-cc6e0e254d1a"},
                {"name":"Casa","value":"57e6963e-e6e2-49f5-b1fb-5a242a74b938"},
                {"name":"Divorcia","value":"3d6d56b0-0311-4b66-9068-4e0ea3e11cc4"},
                {"name":"Vuido","value":"d73b31ea-65d7-4b24-8398-6b97d19f65cc"},
                {"name":"Compaña","value":"4cafe366-359b-422d-9717-d7fa0c7066a6"},
                {"name":"Otro","value":"17845908-e14c-4e4c-96a1-a5e62b6dc24f"}
            ],

            dataSnackbar:{
                timeout:5000,
                color:'success',
                estado:true,
                active:false,
                text:'',
                icon:'' 
            },
            adres:Directions,
            adresSelect:{},
            adresSelectBieu:{},
            adresSelectNobo:{},
            overlayFile:false,

            rul: {
                required: value => !!value ||  'E veld aki ta obligatorio',
                email: value => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/.test(value) || 'E email adres no ta den un formato corecto',
                selectReq: value => (!!value || {} ) || 'Por fabor schohe un opcion',
                min6: v => (v || '').length >= 6 || 'Introducir minimo 6 caracteres',
                max6: v => (v || '').length <= 12 || 'Introducir máximo 12 caracteres',
               phone: value => /^[0-9.*()-_#]{0,16}$/.test(value) || 'phone format incorrect'
            },
        }
    },
    methods: {
        // funcion volver
        volver(){
            this.$emit('baiBek');
        },

        activeOverlay(){
            this.overlayFile = true
        },

        desactiveOverlay(){
            this.overlayFile = false
        },

         errorFile(message){
            this.dataSnackbar.text =  message
            this.dataSnackbar.color = 'error'
            this.dataSnackbar.icon = 'mdi-alert-circle'
            this.dataSnackbar.active = true
             setTimeout(()=>{
                this.dataSnackbar.active = false
            },3000)
        },

         successFile(nameFile){
            this.dataSnackbar.text = 'Je bestand '+nameFile+' is succesvol geüpload'
            this.dataSnackbar.color = 'success'
            this.dataSnackbar.icon = 'mdi-check'
            this.dataSnackbar.active = true
             setTimeout(()=>{
                this.dataSnackbar.active = false
            },3000)
        },

        asignedIdRequestFile(type,name_file,type_file){
             this.$emit('asignarFile',type,name_file,type_file)
        },

        selectAdres(adres){
            this.$emit('asignedAdres',adres);
        },
        selectAdresBieu(adres){
            this.$emit('asignedAdresBieu',adres);
        },
        selectAdresNobo(adres){
            this.$emit('asignedAdresNobo',adres);
        }


    },

}
</script>

<style lang="scss">
    .title-form{
        margin-bottom: 35px;
    }
    .img-icon{
        width: 66px;
    }
    .contenedor-peticion{
        border: 1px solid gray;
        border-radius: 5px;
        border-style: dashed;
        position: relative;
        padding-top: 15px;
        margin-bottom: 20px;
        .container-title{
            position: absolute;
            top: -10px;
            left: 12px;
            font-size: 19px;
            font-weight: 400;
            padding-right: 7px;
            padding-left: 4px;
            background: #fff;
            color: #429cc6;
        }
        .celda{
            margin-bottom: -34px;
        }
        .container-radio{
            position: relative;
            border: 1px solid #429cc6;
            border-radius: 7px;
            margin-top: 12px;
            margin-bottom: 10px;
            .icon-start{
                position: absolute;
                right: 2px;
                top: 5px;
            }
        }
        .celd-dastonan{
            margin-bottom: -53px;
        }
        .text-icon{
            position: absolute;
            left: 58px;
            top: 20px;
            font-size: 12px;
            font-weight: 400;
            font-style: italic;
            color: #429cc6;
        }
        .text-format{
            font-size: 11px;
            margin-left: 16px;
            color: #429cc6;
            font-weight: 400;
            font-style: italic;
        }
        .icon-important{
            position: absolute;
            left: 5px;
        }
   }
   .label-form{
        font-weight: 400;
        font-size: 15px;
        margin-left: 2px;
    }
   .btn-submit{
        background: #429cc6;
        font-size: 15px;
        color: #FFF;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        &:hover{
           background: #ff653a;
           box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            
        }
   }

</style>
<template>
     <v-row class="contenedor-peticion">
                <span class="container-title">Declaracion</span>
                <v-col cols="12" >
                    <p>Suscrito ta declara cu ela yena e formulario 
                        segun berdad. Tambe suscrito ta declara di a tuma nota cu:
                    </p>
                    <ul>
                        <li>
                            Mi persona tin e obligacion di notifica e funcionario di bijstand di DAS riba cada cambio cu por tin den su ceno familiar.
                        </li>
                        <li>
                            Tambe mi tin e obligacion pa notifica e seccion di Bijstand na momento cu mi persona of un miembro di mi famia bay den
                             exterior cu vakantie, pa tratamento medico of a emigra, mester notifica seccion di Bijstand inmediatamente. Esaki ta conta 
                             tambe na momento cu e cobrado di bijstand of un miembro di su famia wordo interna den hospital of otro instancia di cuido, 
                             cay cera pa motibo di un echo castigabel;
                        </li>
                        <li>
                            Den caso cu mi no cumpli cu e obligacion nan aki, gobierno lo cobra mi bek tur locual a wordo paga inhustamente;
                        </li>
                        <li>
                            E suma cu mi a ricibi inhustamente lo wordo reteni for di mi bijstand; Segun e reglanan vigente lo retene te cu un 
                            maximo di 10% di entrada total di famia. Si mi tin cu haya un backpay, lo retene maximo di 30%  of parcialmente di e 
                            ackpay pa asina paga e debe cu tin;
                        </li>
                        <li>
                            Den caso cu mi no ta ricibi bijstand mas, e suma cu a wordo cobra inhustamente lo wordo transpasa pa Departamento di Finansas;
                        </li>
                        <li>
                            Den caso cu bo no ta di acuerdo cu e decision cu Departamento a tuma, por apela esaki via L.A.R.; 
                            Por entrega un keho dentro di seis (6) siman (42 dia) un dia después di e fecha di carta di notificacion 
                            cu bo a ricibi na cas.
                        </li>
                        <li>
                            Privacidad: Durante e proceso di bo peticion ta tene cuenta cu e Ordenansa Registracion di Dato Personal 
                            (Landsverordering Persoonsregistratie).
                        </li>
                        <li>
                            Tin solamente 30 dia pa cumpli cu tur rekisito, sino bo peticion ta keda rechasa!!!
                        </li>
                        <li>
                            Tuma bon nota, cu solamente informacion suministra via funcionario di bijstand ta valido.
                        </li>
                    </ul>
                </v-col>

                <v-col cols="11" class="mx-auto container-radio">
                    <p class="text-declaracion">Mi ta na altura di e contenido di e formulario aki y ta compronde e 
                        contenido di e documento den su totalidad. Mi ta bay di acuerdo pa procesa e peticion y para 
                        responsabel pa informacionnan cu a wordo suministra.</p>
                         <v-icon class="icon-start">mdi-star</v-icon>
                         <label syle="color:blue;">
                             <input type="checkbox"  @click="validar($event)" />
                             <span class="ml-3" color="primary">Mi ta di acuerdo</span> 
                         </label>
                </v-col>
            </v-row>

</template>

<script>
export default {
    name:'Declaracion',
    methods:{
        validar(e){
            let checked = e.target.checked
            this.$emit('ValidarCheck',checked)
        }
    }
}
</script>

<style lang="scss">
      .contenedor-peticion{
        border: 1px solid gray;
        border-radius: 5px;
        border-style: dashed;
        position: relative;
        padding-top: 15px;
        margin-bottom: 20px;
        .container-title{
            position: absolute;
            top: -10px;
            left: 12px;
            padding-right: 7px;
            padding-left: 4px;
            background: #fff;
            color: #429cc6;
        }
        .celda{
            margin-bottom: -34px;
        }
        .container-radio{
            position: relative;
            border: 1px solid #429cc6;
            border-radius: 7px;
            margin-top: 12px;
            margin-bottom: 10px;
            .icon-start{
                position: absolute;
                right: 2px;
                top: 5px;
            }
            .text-declaracion{
                font-weight: 300 !important;
            }
        }
        .celd-dastonan{
            margin-bottom: -53px;
        }
        .text-icon{
            position: absolute;
            left: 58px;
            top: 20px;
            font-size: 12px;
            font-weight: 400;
            font-style: italic;
            color: #429cc6;
        }
        .text-format{
            font-size: 11px;
            margin-left: 16px;
            color: #429cc6;
            font-weight: 400;
            font-style: italic;
        }
        .icon-important{
            position: absolute;
            left: 5px;
        }
   }
</style>
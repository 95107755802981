<template>
  <table class="amt">
    <thead>
      <tr>
        <td class="td-2">Entrada(nan)</td>
        <td class="td-2">Desde ki fecha:</td>
        <td class="td-2">Suma</td>
        <td class="td-2">Type</td>
      </tr>
    </thead>
    <tbody>
         <tr v-for="(item,index) of Data" :key="index">
            <td class="td-2" >
                <p>{{item.entrada}} </p>
            </td>
            <td class="td-2">
                <v-text-field type="date" outlined v-model="Data[index].desdeKiFecha"></v-text-field>
            </td>
            <td class="td-2">
                <v-text-field type="number" outlined v-model="Data[index].sumaDenAwg" @blur="validar($event,index)"></v-text-field>
            </td>
            <td class="td-2">
                <v-select
                :items="dataType"
                outlined
                v-model="Data[index].range"
                ></v-select>
            </td>
        </tr>
    </tbody>
  </table>
</template>

<script>
export default {
    props:['Data','title','num'],
    data(){
        return{
            monto:0,
            dataType:['Pa dia','Pa Siman','Pa Quincena','Pa Luna']
        }
    },
    methods:{

        validar(e,index){
          if(e.target.value === ''){
            if(this.title === 'children'){
              this.$emit('entradaChildren',index,this.num)
            }else{
              this.$emit('pension',this.title,index) 
            }
             
          }
          
       }
    }
};
</script>

<style>
</style>
<template>
  <v-container>
    <AppContentCenterTitleAndEmail />

    <div id="preface">
      <strong>Importante!</strong>
      <p>
        Na momento cu bo persona kier apodera un otro persona pa cobra e Bijstand pa un motibo of otro, mester tene bon cuenta cu lo siguiente:
      </p>
      <p>
        Rekisitonan cu mester cumpli cu ne adicional na e 5 puntonan na e parti aki bao ta : 
        Apoderado/a mester trece prueba di su <b>number di impuesto  </b>(Persoonsnummer).
        
      </p>

      <p>Tambe e Apoderado/a mester sa lo siguiente:</p>
      <p>
        <b>
            Como apoderado /a mester pone Departamento di Asunto Social seccion di bijstand na altura tur cambio cu sosode cu e cliente cu bo persona ta bay cobra p’e. 
            Cu consecuensia cu bo por wordo sanciona si bo no avisa. Y e sancion ta cu Gobierno di Aruba por cobra bo tur e placa cu bo cobra di mas pa e cliente.
            Tambe como apoderado/a bo lo mester firma un  papel di 3 idioma tambe y tur esaki mester sosode den presencia di e funcionario/a di Departamento di Asunto Social seccion di bijstand.
        </b>
      </p>

      <p>
        <b> 1. Vacacion den exterior. (estadia di maximo 4 siman). </b>

        <br />
        Mester presenta tanto e cliente y e apoderado/a na Departamento di Asunto Social seccion di bijstand
        Mester trecea un copia di e ticket di vuelo y un copia di paspoort.
      </p>

      <p>
        <b> 2. Ta bay den exterior cu AZV. </b>

        <br />
        Mester presenta tanto e cliente y e apoderado/a na Departamento di Asunto Social seccion di bijstand
        Mester trece un copia di e ticket di vuelo, copia di paspoort y copia di e carta comprobante di AZV. 
      </p>
      <p>
        Tene na cuenta cu si bo persona mester keda mas tempo den exterior di
        locual ta stipula den e carta di AZV, e apoderado mester acudi na AZV pa
        pidi un comprobante nobo cu e prolongacion di estadia. E apoderado
        mester entrega e carta nobo di AZV na Departamento di Asunto Social su
        seccion di Bijstand.
      </p>

      <p>
        <b> 3. Ta interna den Dr. Horacio E. Oduber Hospital (HOH). </b>
        <br />
        Mester agrega un copia di e carta comprobante di HOH.
      </p>

      <p>
        <b> 4. Ta interna den un Centro di Rehabilitacion. </b>
        <br />
        Mester agrega un copia di e carta comprobante di e Centro di
        Rehabilitacion concerni.
      </p>

      <p>
        <b> 5. Cu bo persona a wordo poni bou di curatela. </b>
        <br />
        Mester agrega un copia di e sentencia di Juez.
      </p>
    </div>
    <div>
      <a
        href="/pdf/Declaracion-di-Scol.pdf"
        class="button pdf"
        title="Declaracion di Scol"
        target="_blank"
        >Declaracion di Scol</a
      >
    </div>
    <div>
      <a
        href="/pdf/Declaracion-pa-gastonan-particular-relaciona-cu-malesa.pdf"
        class="button pdf"
        title="Declaracion pa gastonan particular relaciona cu malesa"
        target="_blank"
        >Declaracion pa gastonan relaciona cu malesa</a
      >
    </div>
    <!-- <div><a href="/pdf/Apoderacion AZV Vacacion Curatela.pdf" class="button pdf" title="Apoderacion (AZV/Vacacion/Curatela)" target="_blank">Apoderacion AZV Vacacion Curatela</a></div> -->
    <div>
      <a
        href="/pdf/Interna_HOH_Rehab.pdf"
        class="button pdf"
        title="Apoderacion (Interna HOH/Rehab)"
        target="_blank"
        >Apoderacion Interna HOH Rehab</a
      >
    </div>
    <div>
      <a
        href="/pdf/formulario_lengua_version_actualisa_2023_2.0.pdf"
        class="button pdf"
        title="Carta di 3 idioma"
        target="_blank"
        >Carta di 3 idioma</a
      >
    </div>

    <div>
      <router-link
        :to="{ name: 'AppContentMainMenu' }"
        class="button"
        title="Bay bek"
        >Bay bek</router-link
      >
    </div>
  </v-container>
</template>

<script>
import AppContentCenterTitleAndEmail from "../components/AppContentCenterTitleAndEmail";

export default {
  components: {
    AppContentCenterTitleAndEmail,
  },
};
</script>

<template>
        <v-dialog  class="modal_files" v-model="modalFiles">
            <v-card>
                <v-card-title class="title-modal-confirmation">
                        <v-row>
                            <v-col cols="9" class="text-center"><h4 class="white--text">File Requires</h4></v-col>
                            <v-col cols="3" class="text-right pt-2">  
                                <v-btn icon large color="white"  @click="$emit('close')"><v-icon class="icon-close" color="white">mdi-close</v-icon> </v-btn>
                            </v-col>
                        </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <v-icon  large color="error" class="icon-message" >mdi-file-alert</v-icon> <br>
                            <h3 >
                             Required this files
                            </h3>
                            <v-row>
                                <v-col v-if="Files.length > 0" cols="12" sm="4" md="4">
                                    <h4>Aplicante</h4>
                                    <ul>
                                        <li v-for="item of  Files" :key="item.id"> {{item}} <v-icon color="error">mdi-file-remove</v-icon> </li>
                                    </ul>
                                </v-col>
                                <v-col v-if="FilesPareha.length > 0" cols="12" sm="4">
                                    <h4>Pareha</h4>
                                    <ul>
                                        <li v-for="item of  FilesPareha" :key="item.id"> {{item}} <v-icon color="error">mdi-file-remove</v-icon> </li>
                                    </ul>
                                </v-col>
                                 <v-col v-if="FilesChildrens.length > 0" cols="12" sm="4" md="4">
                                    <h4>Childrens</h4>
                                    <h5>There are children without documents</h5>
                                    <ul>
                                        <li v-for="item of  FilesChildrens" :key="item.id"> {{item}} <v-icon color="error">mdi-file-remove</v-icon> </li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
</template>

<script>
export default {
    props:['modalFiles','Files','FilesPareha','FilesChildrens']
}
</script>

<style lang="scss">
    .v-dialog{
        width: 700px !important;
        @media(max-width:430px){
            width: 90% !important;
        }
    }
</style>
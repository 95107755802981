<template>
    <v-container>
         <v-col cols="12" style="position:relative">
                     <img src="../../assets/images/icon_scan.png">
                     <span class="text-icon">Attach scanned documents</span>
                     <p class="mb-0">{{name}}</p>
                 </v-col>
                 <v-col cols="11" class="mx-auto container-radio " :class="{'input-container':!inputActive,'input-error':inputActive}">
                     <input type="file" class="input-File" @change="uploadFileTemp($event)" ref="MyFile" accept=".pdf, .jpg, .jpeg, .png" />
                     <span class="texto-error" v-show="inputActive">Documento(nan) obligatorio </span>
                 </v-col>
                 <v-col cols="12" style="position:relative">
                     <img src="../../assets/images/icon_important.png" class="icon-important" />
                     <span class="text-format">Documento cu wordo uploaded no por ta mas grandi cu {{size}}Mb . Solamente documentonan di e siguiente formatonan ta permiti:{{format}} </span>
                  </v-col>
    </v-container>
 </template>
 <script>
 export default {
     name:'UploadFile', 
     props:['name','size','format','id','fileTypeEnum','fileSection','idFile'],
     data(){
         return{
             fileImg:{},
             isAplicant:false,
             isPareha:false,
             isChild:false,
             voidInput:true,
             dataFile:'',
         }
     },
     computed:{
         inputActive:function(){
             let active = this.idFile === '' ? true : false;
             return active;
         }
     },
     methods: {
         //funcion para validar el archivo adjuntado
         uploadFileTemp(e){
             var SIzeFile = this.size * 1000000
             var files = e.target.files || e.dataTransfer.files
             if (!files.length) return
             this.fileImg = files[0]
             // valdiamos que el file sea del formato que permitimos .pdf, .png o .jpg
             if(this.fileImg.type === "application/pdf" || this.fileImg.type === "image/png" || this.fileImg.type === "image/jpg" || this.fileImg.type === "image/jpeg"){
                 // si es del formato correcto validamos que el tamaño del file no exceda el admitido
                  if(this.fileImg.size > SIzeFile){
                     this.$refs.MyFile.value = null;
                     let message = `Er is een fout opgetreden, het bestand is groter dan ${this.size}Mb`
                     this.$emit('error',message)
                 }else{
                     //    si no es mayor el tamaño ejecutamos la funcion para agg o remplace file
                    let reader = new FileReader();
                    reader.readAsDataURL(this.fileImg);
                    reader.onload = function() {
                            let base64String = reader.result;
                            sessionStorage.setItem('base',base64String);
                            //this.$emit('asigned',base,this.fileSection)
                            // Aquí puedes hacer lo que desees con la cadena base64, como enviarla a un servidor o utilizarla en tu aplicación.
                    };
                    this.$emit('asigned',this.fileSection,this.fileImg.name,this.fileImg.type)
                    
                 }
             }else{
                 this.$refs.MyFile.value = null;
                 let message = `bestandsformaatfout, u kunt alleen bestanden toevoegen, .pdf, .png of .jpeg`
                 this.$emit('error',message)
                 
             }
         },


      }
 }
 </script>
 
 <style lang="scss">
  .input-File{
      width: 100% !important;
  }
  .input-container{
      border: 1px solid #429cc6 !important;
  }
  .input-error{
      border: 1px solid red !important;
  }
  .texto-error{
      color:red !important;
  }
 </style>
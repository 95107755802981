<template>
        <v-dialog  class="modal_files" v-model="modalError" max-width="400">
            <v-card>
                <v-card-title class="error">
                        <v-row>
                            <v-col cols="10" class=""><h5 class="white--text">Bo peticion no a wordo procesa</h5></v-col>
                            <v-col cols="2" class="text-right pt-2">  
                                <v-btn icon  color="white"  @click="$emit('closeModalError')"><v-icon class="icon-close" color="white">mdi-close</v-icon> </v-btn>
                            </v-col>
                        </v-row>
                </v-card-title>
                <v-card-text class="text-center py-5 px-5">
                    <v-icon class="icon-close" color="warning" large>mdi-alert</v-icon>
                    <h3>
                        Tin un of mas informacion/documento rekeri cu falta, 
                        por fabor verifica cu tur informacion/documento rekeri
                         a wordo yena/agrega.
                    </h3>
            
                </v-card-text>
            </v-card>
        </v-dialog>
</template>

<script>
export default {
    props:['modalError']
}
</script>

<style lang="scss">
    .v-dialog{
        width: 400px !important;
        @media(max-width:400px){
            width: 90% !important;
        } 
        h5{
            font-size: 18px !important;
            @media(max-width:400px){
                font-size: 15px !important;
            } 
        }
    }
</style>
<template>
    <v-container>
       <ButtonGoHome />
       <AppContentHeaderFormBijstand  title="Formulario pa peticion pa aumento" />
       <AppContentPreface /> 
       <AppFormPeticionAumento />
    </v-container>
</template>
<script>
import ButtonGoHome from '../components/elements/ButtonGoForms'
import AppContentHeaderFormBijstand from '../components/AppContentHeaderFormBijstand'
import AppContentPreface from '../components/AppContentPreface'
import AppFormPeticionAumento from '../components/forms/PeticionDaAumento'

export default {
    components: {
        ButtonGoHome,
        AppContentHeaderFormBijstand,
        AppContentPreface,
        AppFormPeticionAumento
    }
}

</script>
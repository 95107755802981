<template>
        <v-col cols="12">
                    <v-row class="celd-dastonan mb-1">
                         <v-col cols="6">
                             <p><b>Gasto</b> </p>
                        </v-col>
                        <v-col cols="6">
                            <p><b>Suma den AWG</b> </p>
                        </v-col>
                    </v-row>
                    
                    <v-row class="celd-dastonan" v-for="(item,index) of Data" :key="index">
                          <v-col cols="6">
                            <p>{{item.gasto}} </p>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number"  outlined label="Suma den AWG" v-model="Data[index].suma" ></v-text-field>
                        </v-col>
                    </v-row>
            </v-col>
</template>

<script>
export default {
    props:['Data']
}
</script>

<style>

</style>
<template>
<v-container>

    <Snackbar
    :datos="dataSnackbar"
    ></Snackbar>

    <v-overlay :value="overlayFile">
        <v-progress-circular
        indeterminate
        color="info"
        ></v-progress-circular>
        <br>
    </v-overlay>

     <v-row  v-for="(item,index) in info" :key="index"  class="px-4 mb-10 card-data">
                
                <div class="title-data">
                    <p>YIU #{{index+1}}</p>
                </div>
                <v-col cols="12">

                    <!-- 2 . Datonan aplicante -->
                    <v-row class="contenedor-peticion">
                            <span class="container-title">2. Datonan yiu</span>
                            <v-col cols="12"  class="celda">
                                <span class="label-form">Fam</span>
                                <v-text-field type="text" append-icon="mdi-star"  outlined v-model="info[index].datonan.fam" :rules="[rul.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12"  class="celda" >
                                <span class="label-form">Prome nomber(nan)</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined v-model="info[index].datonan.nan" :rules="[rul.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12"  class="celda" >
                                <span class="label-form">Adres</span>
                                <v-row>
                                    <v-col cols="8">
                                        <v-autocomplete 
                                        :items="adres"
                                        item-text="name"
                                        item-value="name"
                                        outlined
                                        append-icon="mdi-star" 
                                        v-model="info[index].datonan.adres"
                                        :rules="[rul.selectReq]"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field type="text" append-icon="mdi-star" outlined placeholder="#" v-model="info[index].datonan.nummerAdres" :rules="[rul.required]"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12"   class="celda">
                                <span class="label-form">Distrito</span>
                                <v-autocomplete   :items="District"
                                    item-value="uuid" item-text="name"
                                    v-model="info[index].datonan.district"
                                    autocomplete="off"
                                    outlined
                                    append-icon="mdi-star"
                                    :rules="[rul.selectReq]"
                                    >
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="12"   class="celda">
                                <span class="label-form">Fecha di nacemento (dd-mm-yyyy | Dia / Luna / Aña)</span>
                                <v-text-field type="date" outlined v-model="info[index].datonan.fechaDiNacimento" @change="habilityDataTrahaChildren(info[index].datonan.fechaDiNacimento,index)" :rules="[rul.required]"></v-text-field>
                            </v-col>
            

                            <v-col cols="12"   class="celda">
                                <span class="label-form">Lugar di nacemento</span>
                                <v-autocomplete  :items="countrys"
                                    item-value="name" item-text="name_en"
                                    v-model="info[index].datonan.lugarDiNacemento"
                                    autocomplete="off"
                                    outlined
                                    append-icon="mdi-star"
                                    :rules="[rul.selectReq]"
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12"   class="celda">
                                <span class="label-form">Nacionalidad</span>
                                <v-autocomplete   :items="countrys"
                                    item-value="name" item-text="name_en"
                                    v-model="info[index].datonan.nacionalidad"
                                    autocomplete="off"
                                    outlined
                                    append-icon="mdi-star"
                                    :rules="[rul.selectReq]"
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12"  class="celda" >
                                <span class="label-form">Number di telefon</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined v-model="info[index].datonan.numberDiTelefon" :rules="[rul.required,rul.phone,rul.min6]"></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12"  class="celda">
                                <div class="note2 text-justify">
                                    <p >Pa bo peticion di bijstand por keda procesa mas lihe posibel,
                                        ta hopi importante pa pone un e-mail adres valido. Tene na cuenta
                                        cu bo persona lo ricibi tur confirmacion via e e-mail adres.</p>
                                </div>
                                <span class="label-form">Email</span>
                                <v-text-field type="email" append-icon="mdi-star" outlined  v-model="info[index].datonan.email"></v-text-field>
                            </v-col> -->
                            <v-col cols="11" class="mx-auto container-radio" >
                                <p>Sexo:</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="info[index].datonan.sexo">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Masculino"  value="Masculino"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="Femenino"  value="Femenino"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <v-col cols="11" class="mx-auto container-radio" v-if="!info[index].datonan.showTraha">
                                <p>Ta bay scol den dia?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="info[index].datonan.taBayScolDenDia">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- validate if ta bay scol den dia = SI -->

                            <v-col cols="12"  class="celda" v-if="info[index].datonan.taBayScolDenDia === 'Si'">
                                <span class="label-form">Nomber di scol</span>
                                <v-autocomplete :items="scols"
                                    item-value="name" item-text="name"
                                    autocomplete="off"
                                    outlined
                                    append-icon="mdi-star"
                                    v-model="info[index].datonan.nomberDiScol"
                                >
                                </v-autocomplete>
                            </v-col>
            

                            <!-- validacion estado civil -->

                            <!-- <v-col cols="12"   class="celda" >
                                <span class="label-form">Estado Civil</span>
                                <v-autocomplete  :items="estadoCivil"
                                    item-value="uuid" item-text="name"
                                    v-model="info[index].datonan.estadoCivil"
                                    @change="changeEstadoCivil(info[index].datonan.estadoCivil,index)"
                                    autocomplete="off"
                                    outlined
                                    append-icon="mdi-star"
                                    >
                                </v-autocomplete>
                            </v-col> -->

                            <v-col cols="12"  class="celda" v-if="info[index].datonan.casada" >
                                <span class="label-form">Yena fecha cu a casa</span>
                                <v-text-field type="date"  v-model="info[index].datonan.fechaCasa" outlined ></v-text-field>
                            </v-col>

                            <v-col cols="12"  class="celda" v-if="info[index].datonan.divorcia">
                                <span class="label-form">Yena fecha cu a divorcia</span>
                                <v-text-field type="date" v-model="info[index].datonan.fechaDivorcia" outlined ></v-text-field>
                            </v-col>

                            <!-- fin validacion estado civil -->

                            <v-col cols="12"  class="celda" >
                                <span class="label-form">Number di cedula</span>
                                <v-text-field type="text" append-icon="mdi-star" v-model="info[index].datonan.numberDiCedula" v-mask="'##-##-##-##'" outlined :rules="[rul.required]"></v-text-field>
                            </v-col>
                            

                            <!-- <UploadFile 
                                name="Upload carchi di DIMP cu ‘persoonsnummer’ riba dje." 
                                :size=30 
                                format="PDF, JPG and PNG"
                                fileTypeEnum='CHILD'
                                :fileSection=201
                                :numChild="index+1"
                                :idFile="info[index].datonan.idPersonummer"
                                @active="activeOverlay"
                                @desactive="desactiveOverlay"
                                @asigned="asignedIdRequestFile"
                                @error="errorFile"
                                @success="successFile"
                            ></UploadFile> -->
                            
                            <!-- Bo persona tin un cuenta coriente na un banco local? -->

                            <v-col cols="11" class="mx-auto container-radio" >
                                <p>Bo persona tin un cuenta coriente na un banco local?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="info[index].datonan.tinCuentaBancoLocal">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- condicion di cuenta banco = SI -->

                            <v-col cols="12"  class="celda" v-if="info[index].datonan.tinCuentaBancoLocal === 'Si'">
                                    <span class="label-form">Nomber di banco</span>
                                    <v-autocomplete :items="bancos"
                                    item-value="uuid" item-text="name"
                                    autocomplete="off"
                                    outlined
                                    append-icon="mdi-star"
                                    v-model="info[index].datonan.nomberDiBanco"
                                    >
                                    </v-autocomplete>
                            </v-col>

                            <v-col cols="12" class="celda"  v-if="info[index].datonan.tinCuentaBancoLocal === 'Si'">
                                <span class="label-form">Number di cuenta coriente</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined  v-model="info[index].datonan.numberDiCuentaCorriente"></v-text-field>
                            </v-col>
                            
                            <v-col cols="11" class="mx-auto" v-if="info[index].datonan.tinCuentaBancoLocal === 'Si'">
                                <div class="note2">
                                    <p>NO ATM CARDS!!!</p>
                                </div>
                            </v-col>
                        

                            <UploadFile 
                                v-if="info[index].datonan.tinCuentaBancoLocal === 'Si'"
                                name="caarchi oficial di banco" 
                                :size=30 
                                format="PDF, JPG and PNG"
                                fileTypeEnum='CHILD'
                                :fileSection=301
                                :numChild="index+1"
                                :idFile="info[index].datonan.idCartaDiBanco"
                                @active="activeOverlay"
                                @desactive="desactiveOverlay"
                                @asigned="asignedIdRequestFile"
                                @error="errorFile"
                                @success="successFile"
                            ></UploadFile>

                            <!-- fin validate cuenta di banco -->


                            <v-col cols="11" class="mx-auto container-radio"  v-if="info[index].datonan.showTraha">
                                <p>Bo persona ta traha?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="info[index].datonan.personataTraha">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si" @click="validateToTraha(info[index].datonan.personataTraha,index)"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No" @click="validateToTraha(info[index].datonan.personataTraha,index)"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>


                            <!-- condicion si traha = SI -->

                            <v-col cols="12" class="celda"  v-if="info[index].datonan.siTraha">
                                <span class="label-form">Nomber di compania</span>
                                <v-text-field type="text" append-icon="mdi-star"  v-model="info[index].datonan.nomberDiCompania"  outlined ></v-text-field>
                            </v-col>

                            <v-col cols="12" class="celda"  v-if="info[index].datonan.siTraha">
                                <span class="label-form">Salario bruto (AWG)</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined  v-model="info[index].datonan.salarioBruto"></v-text-field>
                            </v-col>
                            <v-col cols="11" class="mx-auto mt-5 contenedor-peticion" v-if="info[index].datonan.siTraha">
                                <span class="container-title">Payslip 1</span>

                                <UploadFile 
                                    name="Upload file" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    fileTypeEnum='CHILD'
                                    :fileSection=101
                                    :numChild="index+1"
                                    :idFile="info[index].datonan.idPayslip1"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFile>

                        </v-col>
                        <v-col cols="11" class="mx-auto mt-5 contenedor-peticion" v-if="info[index].datonan.siTraha">
                                <span class="container-title">Payslip 2</span>

                                <UploadFile 
                                    name="Upload file" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    fileTypeEnum='CHILD'
                                    :fileSection=102
                                    :numChild="index+1"
                                    :idFile="info[index].datonan.idPayslip2"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFile>

                        </v-col>
                        <v-col cols="11" class="mx-auto mt-5 contenedor-peticion" v-if="info[index].datonan.siTraha">
                                <span class="container-title">Payslip 3</span>

                                <UploadFile 
                                    name="Upload file" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    fileTypeEnum='CHILD'
                                    :fileSection=103
                                    :numChild="index+1"
                                    :idFile="info[index].datonan.idPayslip3"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFile>

                        </v-col>
                        <v-col cols="11" class="mx-auto mt-5 contenedor-peticion" v-if="info[index].datonan.siTraha">
                                <span class="container-title">Carta di Trabow</span>

                                <UploadFile 
                                    name="Upload file" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    fileTypeEnum='CHILD'
                                    :fileSection=1001
                                    :numChild="index+1"
                                    :idFile="info[index].datonan.idCartaDiTrabaho"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFile>

                        </v-col>
                            
                            <!-- condicion si traga = No -->

                            <v-col cols="12" class="celda" v-if="info[index].datonan.noTraha" >
                                <span class="label-form">Unda a traha ultimo</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined v-model="info[index].datonan.ultimoTraha" ></v-text-field>
                            </v-col>
                            <v-col cols="12"  class="celda" v-if="info[index].datonan.noTraha">
                                <span class="label-form">Motibo cu no ta traha mas?</span>
                                <v-text-field type="text" append-icon="mdi-star" outlined v-model="info[index].datonan.motiboNoTrahaMas"></v-text-field>
                            </v-col>

                            <v-col cols="11" class="mx-auto container-radio" v-if="info[index].datonan.noTraha">
                                <p>Ta dispuesto pa traha?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="info[index].datonan.dispuestoPaTraha" >
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- validate ta dispuesto pa trata = Si -->

                            <v-col cols="12" :id="info[index].datonan.idCard" class="celda mb-5" v-if="info[index].datonan.dispuestoPaTraha === 'Si'" >
                            
                                 <!-- <a  href="http://web.dpl.aw/registracion/" target="_blank" class="link-pdf">
                                        DPL registration
                                </a>   -->

                                   <span class="container-title">Upload carta di inscripcion di DPL</span>

                                <UploadFile 
                                    name="Upload Carta di inscripcion di DPL" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    fileTypeEnum='CHILD'
                                    :fileSection=10002
                                    :numChild="index+1"
                                    :idFile="info[index].datonan.idCartaDidpl"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFile>

                                <v-alert type="error" >
                                    <p class="white--text">Si bo persona no tin e carta di inscripcion di DPL,
                                    bo persona mester subi e link y registra na e parti di DPL prome. Den caso cu bo
                                    persona tin sea e carta of schedule di DPL, por fabor attach esaki na e formulario.
                                    No por completa e peticion aki sin e carta di inscripcion di DPL. </p>
                                </v-alert>

                            </v-col>

                            <!-- fin ta dispuesto pa traha -->

                            <v-col cols="11" class="mx-auto container-radio" v-if="info[index].datonan.noTraha">
                                <p>Tin problema cu salud?</p>
                                <span>Si contesta si mester upload Cita di Medworks</span>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="info[index].datonan.tinProblemaSalud">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- validate Tin problema cu salud? = Si -->

                            <!-- <v-col cols="12" class="celda mb-5" v-if="info[index].datonan.tinProblemaSalud === 'Si'" >
                                
                                <ButtonLinkDownload 
                                name="Declaracion di Dokter di cas/Specialista"
                                :link="downloadUrlEspecialista"
                                :nameDescarga="nameEspecialista"
                                ></ButtonLinkDownload>

                            </v-col> -->

                            <v-col cols="11" class="mx-auto mt-5 contenedor-peticion" v-if="info[index].datonan.tinProblemaSalud === 'Si'">
                                <span class="container-title">Upload Cita di Medworks</span>

                                <UploadFile 
                                    name="Upload file" 
                                    :size=30
                                    format="PDF, JPG and PNG"
                                    fileTypeEnum='CHILD'
                                    :fileSection=601
                                    :numChild="index+1"
                                    :idFile="info[index].datonan.idCartaDoctor"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFile>

                        </v-col>
                        <!-- fin validate tin problema cu salud -->


                            <v-col cols="11" class="mx-auto container-radio" v-if="info[index].datonan.noTraha">
                                <p>Tin problema cu adiccion?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="info[index].datonan.tinProblemaAdiccion">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- validate tin problema cu adiccion = Si -->

                            <v-col cols="11" class="mx-auto container-radio" v-if="info[index].datonan.tinProblemaAdiccion === 'Si'">
                                <p>Den caso di adiccion, ta siguiendo un tratamento of programa?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="info[index].datonan.siguiendoTratamento" >
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- fin validate problema cu adiccion -->

                            <!-- validate si tratamento of programa = Si -->

                            
                            <!-- validate si tratamento of programa = No -->

                            <v-col cols="12" class="celda mb-5"  v-if="info[index].datonan.siguiendoTratamento === 'No'" >
                            
                             <ButtonLinkDownload
                                name="Download Carta FMAA"
                                :link="downloadUrlDeclaracion"
                                :nameDescarga="nameDeclaracion"
                            ></ButtonLinkDownload>

                            </v-col>

                            <v-col cols="11" class="mx-auto container-radio" v-if="info[index].datonan.noTraha">
                                <p>Tin cuido mandatorio (zorgplicht):</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="info[index].datonan.tinCuidoMandatorioZorgplicht">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>

                            <!-- validate if tin cuido mandatorio = SI -->

                            <v-col cols="12"  class="celda" v-if="info[index].datonan.tinCuidoMandatorioZorgplicht === 'Si'">
                                <span class="label-form">Tin cuido mandatorio?</span>
                                <v-autocomplete :items="Options"
                                item-value="uuid" item-text="name"
                                autocomplete="off"
                                outlined
                                append-icon="mdi-star"
                                v-model="info[index].datonan.tinCuidoMandatorio"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="11" class="mx-auto container-radio" v-if="info[index].datonan.noTraha">
                                <p>Tin necesidad di Guia di un Trahado Social:</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="info[index].datonan.tinNecesidadDiGuiaTrahado">
                                <v-row>
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>
                             <v-col cols="12" class="celda mb-5"  v-if="info[index].datonan.tinNecesidadDiGuiaTrahado === 'Si'" >
                            
                                <ButtonLinkDownload
                                    name="Registracion Oficina pa Comunidad (OPC)"
                                    :link="downloadUrlRegistration"
                                    :nameDescarga="nameRegistration"
                                ></ButtonLinkDownload>

                                 <UploadFile 
                                        v-if="info[index].datonan.tinNecesidadDiGuiaTrahado === 'Si'"
                                        name="Registracion Oficina pa Comunidad (OPC)" 
                                        :size=30 
                                        format="PDF, JPG and PNG"
                                        fileTypeEnum='CHILD'
                                        :fileSection=801
                                        :numChild="index+1"
                                        :idFile="info[index].datonan.idCartaOPC"
                                        @active="activeOverlay"
                                        @desactive="desactiveOverlay"
                                        @asigned="asignedIdRequestFile"
                                        @error="errorFile"
                                        @success="successFile"
                                    ></UploadFile>

                            </v-col>

                            <v-col cols="11" class="mx-auto container-radio" v-if="info[index].datonan.noTraha">
                                <p>Yiu ta bay casa dentro di 1 aña?</p>
                                <v-icon class="icon-start">mdi-star</v-icon>
                                <v-radio-group v-model="info[index].datonan.yiuTaBayCasa">
                                <v-row> 
                                        <v-col cols="6">
                                            <v-radio label="Si"  value="Si"></v-radio>
                                        </v-col>   
                                        <v-col cols="6">
                                            <v-radio label="No"  value="No"></v-radio>
                                        </v-col>                         
                                </v-row>
                                </v-radio-group >
                            </v-col>
                        <!--  -->
                        <!-- name="Upload e comprobante di Censo di e childrens[index]"  -->
                            <UploadFile 
                               v-if="info[index].datonan.yiuTaBayCasa === 'Si'"
                                name=" Upload e comprobante di duna man (ondertrouw)" 
                                :size=30 
                                format="PDF, JPG and PNG"
                                fileTypeEnum='CHILD'
                                :fileSection=402
                                :numChild="index+1"
                                :idFile="info[index].datonan.idCartaPareha"
                                @active="activeOverlay"
                                @desactive="desactiveOverlay"
                                @asigned="asignedIdRequestFile"
                                @error="errorFile"
                                @success="successFile"
                            ></UploadFile>



                        <!-- fin validate ta traha = No -->

                    </v-row>

                    <!--3 otro entrada -->
                    <v-row class="contenedor-peticion mt-5">
                        <span class="container-title">3. Otro entrada(nan)(art.12 Lbbv)</span>
                        <v-col cols="11" class="mx-auto container-radio" >
                            <p>Tin otro entrada(nan)?</p>
                            <v-icon class="icon-start">mdi-star</v-icon>
                            <v-radio-group v-model="info[index].otroEntrada.tinOtraEntrada">
                            <v-row>
                                    <v-col cols="6">
                                        <v-radio label="Si"  value="Si" @click="mountedComponentOtroEntrada(info[index].otroEntrada.tinOtraEntrada,index)"></v-radio>
                                    </v-col>   
                                    <v-col cols="6">
                                        <v-radio label="No"  value="No" @click="mountedComponentOtroEntrada(info[index].otroEntrada.tinOtraEntrada,index)"></v-radio>
                                    </v-col>                         
                            </v-row>
                            </v-radio-group >
                        </v-col>

                        <v-col cols="12" class="celda" >

                            <component  title="children" @pensionChildren="restarPensionChildren" :num="index" :is='info[index].otroEntrada.tablePension' :Data="info[index].otroEntrada.tipoDiPension"></component>

                            <component  title="children" @entradaChildren="restartEntradaChildren" :num="index" :is='info[index].otroEntrada.tableEntrada' :Data="info[index].otroEntrada.entradaNan"></component>

                        </v-col>

                    </v-row>

                    <!--4 alimentacion -->
                    <v-row class="contenedor-peticion">
                        <span class="container-title">4. Alimentacion (art.4 decreto nacional bijstand)</span>
                        <v-col cols="12">
                            <p>Den caso cu bo no tin informacion, no sa e paradero di e tata(nan) di bo yui(nan) y no
                            ta desea intermediacion di Directie Voogdijraad encuanto alimentacion, bo yiu of yiunan 
                            no lo bin na remarca pa nan parti(bijslag) den calculacion total di e suma cu lo bo bay 
                            ricibi na bijstand.
                        </p>
                        </v-col>
                        <v-col cols="11" class="mx-auto container-radio">
                            <p>Ta desea intermediacion di Directie Voogdijraad?</p>
                            <v-icon class="icon-start">mdi-star</v-icon>
                            <v-radio-group v-model="info[index].alimentacion.taDeseaIntermediacionDiDirectieVoogdijraad">
                            <v-row>
                                    <v-col cols="6">
                                        <v-radio label="Si"  value="Si"></v-radio>
                                    </v-col>   
                                    <v-col cols="6">
                                        <v-radio label="No"  value="No"></v-radio>
                                    </v-col>                         
                            </v-row>
                            </v-radio-group >
                        </v-col>

                        <v-col cols="11 mx-auto" v-if="info[index].alimentacion.taDeseaIntermediacionDiDirectieVoogdijraad === 'Si'">
                            <div class="note2">
                                <p>Lesa instructionnan importante</p>
                            </div>
                            <p>Tene bon cuenta cu e siguiente pasobra e ta <span>obligatorio:</span></p>
                            <ol class="style01">
                                <li><span>Download</span> e formulario di Directie Voogdijraad. </li>
                                <li><span>Print </span>y <span>yena </span>esaki completo.</li>
                                <li><span>Firma</span> esaki.</li>
                                <li><span>Scan</span> esaki (of saca potret cu un telefon).</li>
                                <li><span>Upload</span> e formulario yena y firma bek na e seccion yama: "Upload formulario di Directie Voogdijraad"</li>
                            </ol>
                            <p>Si bo persona <span>no</span> ta dispone di un scanner, por entrega e formulario yena y firma na Departamento di Asunto 
                            Social su oficina situa na Oranjestad of na San Nicolaas.</p>
                            
                            <p>Si bo persona <span>no</span> cumpli cu e formulario di Directie Voogdijraad bo peticion pa bijstand <span>no</span> por keda procesa.</p>
                            
                            <ButtonLinkDownload
                            name="formulario Solicitud pa Alimentacion"
                            :link="downloadUrlAlimentacion"
                            :nameDescarga="nameAlimentacion"
                            ></ButtonLinkDownload>

                            <v-row class="contenedor-peticion mt-8">
                                <span class="container-title">Upload formulario di Directie Voogdijraad</span>

                                <UploadFile 
                                    name="Upload file" 
                                    :size=30 
                                    format="PDF, JPG and PNG"
                                    fileTypeEnum='CHILD'
                                    :fileSection=501
                                    :numChild="index+1"
                                    :idFile="info[index].alimentacion.idFormularioDiDirectieVoogdijraad"
                                    @active="activeOverlay"
                                    @desactive="desactiveOverlay"
                                    @asigned="asignedIdRequestFile"
                                    @error="errorFile"
                                    @success="successFile"
                                ></UploadFile>
                            </v-row>

                        </v-col>

                        
                    </v-row>

                    <!--5 capital efectivo -->
                    <v-row class="contenedor-peticion">
                        <span class="container-title">5. Capital efectivo (art.6 Lbbv y art.16 Lmza)</span>
                        <v-col cols="12">
                            <p>Cabes di famia of otro miembro di famia tin cuenta na banco y/of di spaar, 
                                credito (manera cesantia, seguro, herencia) y otro capital contante?
                            </p>
                        </v-col>
                        <v-col cols="11" class="mx-auto container-radio">
                            <p>Capital efectivo</p>
                            <v-icon class="icon-start">mdi-star</v-icon>
                            <v-radio-group v-model="info[index].CapitalEfectivo.capitalEfectivo">
                            <v-row>
                                    <v-col cols="6">
                                        <v-radio label="Si"  value="Si" @click="mountedComponentCapitalEfectivo(info[index].CapitalEfectivo.capitalEfectivo,index)"></v-radio>
                                    </v-col>   
                                    <v-col cols="6">
                                        <v-radio label="No"  value="No" @click="mountedComponentCapitalEfectivo(info[index].CapitalEfectivo.capitalEfectivo,index)"></v-radio>
                                    </v-col>                         
                            </v-row>
                            </v-radio-group >
                        </v-col>

                        <v-col cols="12" class="">
                            <component 
                            :is='info[index].CapitalEfectivo.componentTableCapitalEfectivo'
                            :Bancos="bancos"
                            :Data="info[index].CapitalEfectivo.capital"></component>
                        </v-col>
                    </v-row>
                </v-col>
     </v-row>
</v-container>
    
</template>

<script>
import UploadFile from '../components/elements/UploadFile'
import ButtonLinkDownload from '../components/elements/ButtonLinkDownload'
import TableCapitalEfectivo from '../components/elements/TableCapitalEfectivo'
import TableTipoPension from '../components/elements/TableTpoPension'
import TableEntrada from '../components/elements/TableEntrada'
import Snackbar from '../components/elements/Snackbar'
import Directions from '@/assets/addresslist.json'
export default {
    props:['info','District','countrys','bancos','estadoCivil','scols','Options'],
    components:{
        UploadFile,
        ButtonLinkDownload,
        TableCapitalEfectivo,
        TableTipoPension,
        TableEntrada,
        Snackbar
    },
    data(){
        return{
            modal_date:false,
            siTraha:false,
            noTraha:false,  
            adres:Directions,
            //5- capital efectivo
            capitalEfectivo:'',
            componentTableCapitalEfectivo:'',
           dataSnackbar:{
                timeout:5000,
                color:'success',
                estado:true,
                active:false,
                text:'',
                icon:'' 
            },
            downloadUrlEspecialista: `${process.env.BASE_URL}pdf/Cartapadokterdicasofspecialista.pdf`,
            nameEspecialista:"Cartapadokterdicasofspecialista.pdf",
            downloadUrlDeclaracion: `${process.env.BASE_URL}pdf/DeclaracionCAA.pdf`,
            nameDeclaracion:"DeclaracionCAA.pdf",
            downloadUrlRegistration:`${process.env.BASE_URL}pdf/RegistracionOPC.pdf`,
            nameRegistration:'RegistracionOPC.pdf',
            downloadUrlAlimentacion:`${process.env.BASE_URL}pdf/Solicitudpaalimentacion.pdf`,
            nameAlimentacion:'Solicitudpaalimentacion.pdf',
            overlayFile:false,
            rul: {
                required: value => !!value ||  'E veld aki ta obligatorio',
                email: value => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/.test(value) || 'E email adres no ta den un formato corecto',
                selectReq: value => !!value || 'Por fabor schohe un opcion',
                min6: v => (v || '').length >= 6 || 'Introducir minimo 6 caracteres',
                max6: v => (v || '').length <= 12 || 'Introducir máximo 12 caracteres',
               phone: value => /^[0-9.*()-_#]{0,16}$/.test(value) || 'phone format incorrect'
            },
        }
    },
    methods:{

        restarPensionChildren(index,num){
            this.$emit('childrenPensionRestart',index,num)
        },

        restartEntradaChildren(index,num){
            this.$emit('childrenEntradaRestart',index,num)
        },
        //funcion estado civil habilitar selects
        validateEstadoCivil(estado,index){
            if(estado === 'Si'){
                this.info[index].datonan.SelectEstadoCivil = true
            }else{
                this.info[index].datonan.SelectEstadoCivil = false
                this.info[index].datonan.casada = false
                this.info[index].datonan.divorcia = false
            }
        },
         // funcion para habilitar campos date segun opcion
        changeEstadoCivil(value,index){
            this.info[index].datonan.casada = false
            this.info[index].datonan.divorcia = false
            if(value === '57e6963e-e6e2-49f5-b1fb-5a242a74b938'){
                this.info[index].datonan.casada = true
            }else if(value === '3d6d56b0-0311-4b66-9068-4e0ea3e11cc4'){
                this.info[index].datonan.divorcia = true
            }else{
                return
            }
        },
        habilityDataTrahaChildren(fecha_nacimiento,index){
                var hoy = new Date();
                var cumpleanos = new Date(fecha_nacimiento);
                var edad = hoy.getFullYear() - cumpleanos.getFullYear();
                var m = hoy.getMonth() - cumpleanos.getMonth();
                if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                    edad--;
                }
                console.log('edad-->'+edad)
                this.info[index].datonan.showTraha = edad >= 16 ? true : false
                console.log(this.info[index].datonan.showTraha)
        },
         validateToTraha(value,index){
            this.info[index].datonan.noTraha = false,this.info[index].datonan.siTraha = false
            if(value === 'Si'){
               this.info[index].datonan.siTraha = true
               this.resetFieldsToTraha(index)
            }else{
                this.info[index].datonan.noTraha = true
                this.resetFieldsToTraha(index)
            }
        },
         // reset fields para validateToTraha = Si
        resetFieldsToTraha(index){
             this.info[index].datonan.dispuestoPaTraha     = '' 
             this.info[index].datonan.nomberDiCompania     = ''
             this.info[index].datonan.salarioBruto         = ''
             this.info[index].datonan.tinProblemaSalud     = ''
             this.info[index].datonan.tinProblemaAdiccion  = ''
             this.info[index].datonan.siguiendoTratamento  = '' 
             this.info[index].datonan.taBayScolDenDia      = ''
             this.info[index].datonan.nomberDiScol         = ''
             this.info[index].datonan.tinCuidoMandatorioZorgplicht        = ''
             this.info[index].datonan.tinCuidoMandatorio   = ''
             this.info[index].datonan.tinNecesidadDiGuiaTrahado = ''
             this.info[index].datonan.gastonanDiTransportePaLuna = ''
             this.info[index].datonan.yiuTaBayCasa         = '' 
        },
        mountedComponentOtroEntrada(value,index){
            if(value === 'Si'){
                this.info[index].otroEntrada.tablePension = 'TableTipoPension'
                this.info[index].otroEntrada.tableEntrada = 'TableEntrada'
            }else{
                this.info[index].otroEntrada.tablePension = ''
                this.info[index].otroEntrada.tableEntrada = ''
            }
        },
         // 5 capital efectivo
        // funcion para cargar componente de tabla de informacion
        mountedComponentCapitalEfectivo(value,index){
            if(value === 'Si'){
                this.info[index].CapitalEfectivo.componentTableCapitalEfectivo = 'TableCapitalEfectivo'
            }else{
                this.info[index].CapitalEfectivo.componentTableCapitalEfectivo = ''
            }
        },
        
        activeOverlay(){
            this.overlayFile = true
        },
        desactiveOverlay(){
            this.overlayFile = false
        },
        
         errorFile(message){
            this.dataSnackbar.text =  message
            this.dataSnackbar.color = 'error'
            this.dataSnackbar.icon = 'mdi-alert-circle'
            this.dataSnackbar.active = true
             setTimeout(()=>{
                this.dataSnackbar.active = false
            },3000)
        },
 
         successFile(nameFile){
            this.dataSnackbar.text = 'Je bestand '+nameFile+' is succesvol geüpload'
            this.dataSnackbar.color = 'success'
            this.dataSnackbar.icon = 'mdi-check'
            this.dataSnackbar.active = true
             setTimeout(()=>{
                this.dataSnackbar.active = false
            },3000)
        },
        asignedIdRequestFile(type,id,numChild){
            let index = numChild - 1;
            switch(type){
                case 201:
                    this.info[index].datonan.idPersonummer = id
                break;
                case 301:
                    this.info[index].datonan.idCartaDiBanco = id
                break;
                case 101:
                    this.info[index].datonan.idPayslip1 = id
                break;
                case 102:
                    this.info[index].datonan.idPayslip2 = id
                break;
                case 103:
                    this.info[index].datonan.idPayslip3 = id
                break;
                case 1001:
                    this.info[index].datonan.idCartaDiTrabaho = id
                break;
                case 601:
                    this.info[index].datonan.idCartaDoctor = id
                 break;
                case 801:
                    this.info[index].datonan.idCartaOPC = id
                 break;
                case 501:
                    this.info[index].alimentacion.idFormularioDiDirectieVoogdijraad = id
                 break;
                case 402:
                    this.info[index].datonan.idCartaPareha = id
                 break;
            }
        }
    }
}
</script>

<style lang="scss">
   .link-pdf{
       position: relative;
        background: #429cc6;
        text-decoration: none;
        color: #FFF !important;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 500;
        max-width: 100%;
        padding-left: 130px;
        padding-right: 130px;
        padding-top: 13px;
        padding-bottom: 12px;
        text-align: center;
        height: 56px;
        border-radius: 5px;
        outline: none;
        &:hover{
           background: #ff653a;
           box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            
        }
        @media(max-width:420px){
            font-size: 13px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
</style>
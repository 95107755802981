<template>
    <v-container>
       <ButtonGoHome />
       <AppContentHeaderFormBijstand  title="Formulario pa aplicacion di bijstand" />
       <AppContentPreface />
       <AppFormNewBijstand />
    </v-container>
</template>
<script>
import ButtonGoHome from '../components/elements/ButtonGoForms'
import AppContentHeaderFormBijstand from '../components/AppContentHeaderFormBijstand'
import AppContentPreface from '../components/AppContentPreface'
import AppFormNewBijstand from '../components/forms/PeticionNoboPaBijstand'

export default {
    components: {
        ButtonGoHome,
        AppContentHeaderFormBijstand,
        AppContentPreface,
        AppFormNewBijstand
    },
    data(){
        return{
            show:false,
            init:true
        }
    },
    methods: {
        next(){
            this.init = false
            this.show = true
        }
    },
}

</script>
<style >

</style>
<template>
    <v-app id="inspire">
           
          <v-navigation-drawer v-model="on_drawer" app color="primary" dark> 

            <v-row>
                <v-col cols="12" class="text-center mt-5 mb-3">
                      <!-- <v-icon color="indigo">mdi-account</v-icon> -->
                    <img src="../assets/icon-das-white.svg" alt="" width="80"> 
                   <h4 class="white--text mb-0">DAS LOKET SOCIAL</h4> 
                </v-col>   
            </v-row>
          
                

                 <v-list dense>
                    <v-list-item to="/admin/paBijstand" color="white" >
                        <v-list-item-icon>
                            <v-icon class="">mdi-briefcase-plus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title >Aplica pa Bijstand</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/admin/paAumento" color="white" >
                        <v-list-item-icon>
                            <v-icon >mdi-briefcase</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title >Peticion pa aumento</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/admin/formDiCambio" color="white" >
                        <v-list-item-icon>
                            <v-icon >mdi-briefcase-search</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title >Notificacion di cambio</v-list-item-title>
                    </v-list-item>
                   
                 </v-list>

            </v-navigation-drawer>
        
            <v-app-bar app color="white"  class="barra">
              <v-app-bar-nav-icon @click="on_drawer = !on_drawer" color="primary"><v-icon large>mdi-menu</v-icon></v-app-bar-nav-icon>

              <img src="../assets/text-das.png" alt=""  class="ml-2"> 
        
              <v-toolbar-title></v-toolbar-title>

               <div class="text-center logout-session">
                    <v-menu>
                    <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                color="primary"
                                v-bind="attrs"
                                 v-on="{ ...tooltip, ...menu }"
                                >
                                <v-icon dark>
                                    mdi-account
                                </v-icon>
                                </v-btn>
                        </template>
                        <span>Logout</span>
                        </v-tooltip>
                    </template>
                    <v-card>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn icon color="primary" >
                                        <v-icon>mdi-logout</v-icon>
                                    </v-btn>
                                    
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                          <v-btn text color="primary" > Logout</v-btn> 
                                        </v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                    <v-btn
                                        color="error"
                                        text
                                        @click="fav = !fav"
                                    >
                                        Cancel
                                    </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                        </v-list>
                    </v-card> 
                    </v-menu>
                </div>


          </v-app-bar>
            <v-main>
              <v-container>
                  <slot/>
              </v-container>
            </v-main>
        </v-app> 
</template>

<script>
export default {
    data(){
        return{
            on_drawer:true,

        }
    },
    methods:{
         
    }
}
</script>

<style lang="scss">
   .barra{
         //
        .logout-session{
            position: absolute;
            right: 5px;
        }
   }

</style>
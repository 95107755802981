<template>
   <v-container ref="printMe">

       
        <v-overlay :value="overlay">
            <v-progress-circular
            indeterminate
            color="info"
            ></v-progress-circular>
            <br>
        </v-overlay>

          <!-- modal error submit void fields -->
        <Modal-Error
        :modalError="modal_error"
        @closeModalError="closeModalerror"
        ></Modal-Error>

        <!-- modal confirmation -->
        <ModalConfirmation
         :modal="modal_confirmation"
         :title="title_modal"
         :msjSuccess="messageSuccess"
         :msjError="messageError"
         :error="ocurrioError"
         :success="Success"
         @closeModal="modalClose"
        ></ModalConfirmation>

       <AppContentCenterTitleAndEmail v-show="showMenu" />

         <v-container >
             <!-- <div><a  class="button online"  @click="cambioDiForm('address','Cambio di adres',$event)" title="Cambio di adres">Cambio di adres</a></div>
            <div><a  class="button online" @click="cambioDiForm('lastname','Cambio di fam',$event)" title="Cambio di fam">Cambio di fam</a></div>
            <div><a  class="button online" @click="cambioDiForm('marital','Cambio di estado civil',$event)" title="Cambio di estado civil">Cambio di estado civil</a></div>
            <div><a  class="button online" @click="cambioDiForm('job','A haña trabou',$event)" title="declara cu a haña trabou">A haya trabou</a></div>
            <div><a  class="button online" @click="cambioDiForm('cancel','Stop bijstand',$event)" title="Stop bijstand">Kier stop bijstand</a></div>
            <div><a  class="button online" @click="cambioDiForm('phone','Cambio di telefon',$event)" title="Cambio di telefon">Cambio den number di telefon/celular</a></div>
            <div><a  class="button online" @click="cambioDiForm('authorize','Apodera un otro persona pa cobra bijstand',$event)" title="apodera un otro persona pa cobra bijstand">Kier apodera un otro persona pa cobra bijstand</a></div>
            <div><a  class="button online" @click="cambioDiForm('other','notificacion di cambio | Otro',$event)" title="notificacion di cambio | Otro">Otro tipo di cambio</a></div> -->

            <div><router-link :to="{ name: 'AppContentMainMenu' }" class="button" title="Bay bek">Bay bek </router-link></div>
         </v-container>
          <v-form ref="form"  lazy-validation>
            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                     :items="menu"
                     item-text="name"
                     label="Notificacion di cambio(nan)"
                     outlined
                     v-model="itemForm"
                     @change="cambioDiForm(itemForm)"
                     return-object
                    ></v-autocomplete>
                </v-col>
            </v-row>

             <!-- form di cambio -->
            <FormDiCambio 
                :name="nameCambio"
                :subtitle="subtitulo"
                :icon="iconCambio"
                :info="datos"
                v-show="!showMenu"
                :showCampos="ShowFields"
                @baiBek="showFalse"
                @asignedAdres="selectadress"
                @asignedAdresBieu="selectadressBieu"
                @asignedAdresNobo="selectadressNobo"
                @asignarFile="asignandoFile"
             />

             <Declaracion 
               v-show="!showMenu"
               @ValidarCheck="validarCheck" 
            ></Declaracion>

             <ButtonSubmit 
              v-show="btn_submit" 
              :name="nameCambio"
              @submit="submitFormDiCambio"
              ></ButtonSubmit>
          </v-form>

   </v-container>

</template>

<script>
import axios from 'axios'
import AppContentCenterTitleAndEmail from '../components/AppContentCenterTitleAndEmail'
import Declaracion from '@/components/elements/Declaracion'
import ButtonSubmit from '@/components/elements/ButtonSubmitDiCambio'
import FormDiCambio from '../components/forms/FormDiCambio'
import ModalError from '../components/elements/ModalErrorSend.vue'
import ModalConfirmation from '../components/elements/ModalConfirmation'
import { jsPDF } from "jspdf";

export default {
    components: {
        AppContentCenterTitleAndEmail,
        FormDiCambio,
        Declaracion,
        ModalError,
        ModalConfirmation,
        ButtonSubmit
    },
    data(){
        return{
            showMenu:true,
            nameCambio:'',
            iconCambio:'',
            subtitulo:'',
            overlay:false,
            modal_error:false,
            modal_confirmation:false,
            title_modal:'',
            ocurrioError:false,
            Success:false,
            messageError:'',
            messageSuccess:'',
            ShowFields:{
                adres:true,
                cedula:true,
                telefon:true
            },
            datos:{
                notification:"",
                TipoDiCambioId:"",
                key:'',
                pdfFile:'',
                fam:"",
                FamNobo:"",
                promeNomber:"",
                numberDiTelefon:"",
                email:"",
                numberDiCedula:"",
                adresId: "",
                numberAdres: "",
                adresNoboId: "",
                numberAdresNobo: "",
                districto: "",
                estadoCivilBieu:"",
                estadoCivilNobo:"",
                estadoCivilBieuwId:"",
                estadoCivilNoboId:"",
                motiboKierStopBijstand:"",
                motiboKierApoderaPersona:"",
                personaNotificacionDiCambio:"",
                naUndaHayaTrabou:"",
                fechaHayaTrabou:"",

                fileIdCartaTrabou:"",
                fileCartaTrabouContent:"",
                fileCartaTrabouName:"",

                //fileIdCartaDoctor:"",
                fileCartaDoctorContent:"",
                fileCartaDoctorName:"",

                //fileIdIdentificationBijstand:"",
                fileIdentificationBijstandContent:"",
                fileIdentificationBijstandName:"",

    
                //fileIdIdentificationApoderado:"",
                fileIdentificationApoderadoContent:"",
                fileIdentificationApoderadoName:"",

                disclaimerChecked:false,
                DisclaimerTextWhenSubmitted:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorum, dignissimos rem, facilis temporibus explicabo id saepe odit sed corporis cumque unde, reprehenderit aspernatur. Consectetur quos repellendus modi voluptates similique harum!"
            },
            itemForm:{},
            menu:[
                {uuid:"163ccc38-ef33-4ddc-bf54-a07b4b4c0f7c",name:"Cambio di adres",title:"Cambio di adres",subtitle:"Cambio di adres",icon:"address"},
                {uuid:"21b53a5c-49b1-4ba8-9c57-8fa664f6a674",name:"Cambio di fam",title:"Cambio di fam",subtitle:"Cambio di fam",icon:"lastname"},
                {uuid:"70d6c2f8-5098-4150-9c3e-2e71ef22385f",name:"Cambio di estado civil",title:"Cambio di estado civil",subtitle:"Cambio di estado civil",icon:"marital"},
                {uuid:"b39f9272-6058-45a5-b728-a4c2df5717b2",name:"A haya trabou",title:"declara cu a haña trabou",subtitle:"A haña trabou",icon:"job"},
                {uuid:"efd4bd4f-d279-4d41-9a70-df7890bfe6e2",name:"Kier stop bijstand",title:"Stop bijstand",subtitle:"Stop bijstand",icon:"cancel"},
                {uuid:"d63c65c4-c799-4f21-8c88-5ac2d0a116e6",name:"Cambio den number di telefon/celular",title:"Cambio di telefon",subtitle:"Cambio di telefon",icon:"phone"},
                {uuid:"81d39b53-6384-4350-89e7-f71fc9e97f80",name:"Kier apodera un otro persona pa cobra bijstand",title:"apodera un otro persona pa cobra bijstand",subtitle:"Apodera un otro persona pa cobra bijstand",icon:"authorize"},
                {uuid:"ccf3baad-ae46-44b1-8f30-2a7109a2750e",name:"Otro tipo di cambio",title:"notificacion di cambio | Otro",subtitle:"notificacion di cambio | Otro",icon:"other"}
            ],

            btn_submit:false
        }
    },
    mounted(){
        this.getRequestKey()
    },
    methods: {

        async getRequestKey(){
            try{
                const response = await axios.post('/api/requests/requestkey',{});
                localStorage.setItem('requestKey', response.data.requestKey)  
                this.datos.key = response.data.requestKey
            }catch(err){
                console.log(err)
            }
        },

        //function validacion di campos

        cambioDiForm(item){
            this.datos.notification = item.name
            this.datos.TipoDiCambioId = item.uuid
            this.nameCambio = item.title
            this.iconCambio = item.icon
            this.subtitulo = item.subtitle
            if(item.icon === 'other' ){
                this.ShowFields = {adres:false,cedula:false,telefon:false}
            }else if(item.icon === 'address'){
                 this.ShowFields.adres = false
            }else if(item.icon === 'fam'){
                   this.ShowFields.adres = true
            }else if(item.icon === 'phone'){
                   this.ShowFields.telefon = false               
            }else if(item.icon === 'authorize'){
                this.ShowFields = {adres:false,cedula:false,telefon:false}
            }
            else{
                this.ShowFields = {adres:true,cedula:true,telefon:true}
            }
            setTimeout(() => this.showMenu = false, 400);
        },

        // show menu

        showFalse(){
            this.showMenu = true
        },

        validarCheck(checked){
            this.btn_submit = checked
            this.datos.disclaimerChecked = checked
        },

        selectadress(adres){
            this.datos.adres = adres.name
            this.datos.uuidAdres = adres.uuid
        },
        selectadressBieu(adres){
            this.datos.adresBieuw = adres.name
            this.datos.uuidAdresBieuw = adres.uuid
        },
        selectadressNobo(adres){
            this.datos.adresNobo = adres.name
            this.datos.uuidAdresNobo = adres.uuid
        },
        activandoOverlay(){
            this.overlay = true
        },
        desactivandoOverlay(){
            this.overlay = false
        },

        closeModalerror(){
            this.modal_error = false;
        },
        modalClose(){
            this.modal_confirmation = false
        },

        async createExportPDF(){
                this.$html2canvas(this.$refs.printMe, {
                DPI: 300, // Exportar definición PDF
                }).then((canvas) => {
                    var contentWidth = canvas.width;
                    var contentHeight = canvas.height;
            
                  // una página PDF muestra la altura de lienzo generada por la página HTML;
                    var pageHeight = contentWidth / 592.28 * 841.89;
                            // No genere la altura de la página HTML del PDF
                    var leftHeight = contentHeight;
                            // Página PDF Desplazamiento
                    var position = 0;
                            // Página HTML generada por el lienzo en el PDF (el tamaño del papel A4 [595.28, 841.89])
                    var imgWidth = 595.28;
                    var imgHeight = 592.28 / contentWidth * contentHeight;
            
                    var pageData = canvas.toDataURL('image/jpeg', 1.0);
                    var pdf = new jsPDF('', 'pt', 'a4',);
            
                            // Hay dos alturas para distinguir, una es la altura real de la página HTML, y generando páginas altura de PDF (841.89)
                            // Cuando el contenido no excede el rango de pantalla PDF PAGE, sin paginación
                    if (leftHeight < pageHeight) {
                        pdf.addImage(pageData, 'JPEG', 5, 0, imgWidth, imgHeight);
                    } else {
                        while (leftHeight > 0) {
                            pdf.addImage(pageData, 'JPEG', 5, position, imgWidth, imgHeight)
                            leftHeight -= pageHeight;
                            position -= 841.89;
                        // Evitar agregar una página en blanco
                            if (leftHeight > 0) {
                                pdf.addPage();
                            }
                        }
                    }
                    let file = pdf.output("blob")
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = function(){
                        var base64data = reader.result;
                        this.datos.pdfFile = base64data;
                    }
                   
                })
        },

        async ExportPDF(){
                this.$html2canvas(this.$refs.printMe, {
                DPI: 300, // Exportar definición PDF
                }).then((canvas) => {
                    var contentWidth = canvas.width;
                    var contentHeight = canvas.height;
            
                  // una página PDF muestra la altura de lienzo generada por la página HTML;
                    var pageHeight = contentWidth / 592.28 * 841.89;
                            // No genere la altura de la página HTML del PDF
                    var leftHeight = contentHeight;
                            // Página PDF Desplazamiento
                    var position = 0;
                            // Página HTML generada por el lienzo en el PDF (el tamaño del papel A4 [595.28, 841.89])
                    var imgWidth = 595.28;
                    var imgHeight = 592.28 / contentWidth * contentHeight;
            
                    var pageData = canvas.toDataURL('image/jpeg', 1.0);
                    var pdf = new jsPDF('', 'pt', 'a4',);
            
                            // Hay dos alturas para distinguir, una es la altura real de la página HTML, y generando páginas altura de PDF (841.89)
                            // Cuando el contenido no excede el rango de pantalla PDF PAGE, sin paginación
                    if (leftHeight < pageHeight) {
                        pdf.addImage(pageData, 'JPEG', 5, 0, imgWidth, imgHeight);
                    } else {
                        while (leftHeight > 0) {
                            pdf.addImage(pageData, 'JPEG', 5, position, imgWidth, imgHeight)
                            leftHeight -= pageHeight;
                            position -= 841.89;
                        // Evitar agregar una página en blanco
                            if (leftHeight > 0) {
                                pdf.addPage();
                            }
                        }
                    }
                    pdf.save('form-di-cambio.pdf');     
                })
        },

        asignandoFile(fileType,name_file,type){
            let file = '';
            let base64 = sessionStorage.getItem('base');  
            if(type === 'application/pdf'){
                file = base64.replace('data:application/pdf;base64,','');
            }else if(type === 'image/png'){
                file = base64.replace('data:image/png;base64,','');
            }else if(type === 'image/jpeg'){
                file = base64.replace('data:image/jpeg;base64,','');
            }else if(type === 'image/jpg'){
                file = base64.replace('data:image/jpg;base64,','');
            }

            

                 
            switch(fileType){
                case 1001:
                    this.datos.fileCartaTrabouContent = file
                    this.datos.fileCartaTrabouName = name_file
                break;
                case 601:
                    this.datos.fileCartaDoctorContent = file
                    this.datos.fileCartaDoctorName    = name_file
                 break;
                case 401:
                    this.datos.fileIdentificationBijstandContent = file
                    this.datos.fileIdentificationBijstandName  = name_file
                 break;
                case 402:
                    this.datos.fileIdentificationApoderadoContent = file
                    this.datos.fileIdentificationApoderadoName = name_file
                 break;
                default:
                    break;
            }
            sessionStorage.removeItem('base');
        },
        //submit form di cambio
        async  submitFormDiCambio(e){
            e.preventDefault() 
            this.activandoOverlay()
            await this.createExportPDF()
            if (this.$refs.form.validate()) {
                try{
                    const response = await axios.post('/api/loketsocial/cambio/notificaciondicambio/',this.datos)
                    console.log(response.data)
                    this.desactivandoOverlay()
                    this.$router.push('/forms/changes/success/'+response.data.requestNumber)
                    this.ExportPDF()
                }catch(err){
                    console.log(err)
                    this.ocurrioError = true
                    this.desactivandoOverlay()
                    this.title_modal = 'Tin un eror cu bo peticion'
                    this.messageError = '"Por fabor verifica cu tur informacion ta yena correctamente y purba di nobo."'
                    this.modal_confirmation = true
                }
            }else{
                this.desactivandoOverlay()
                this.modal_error = true
            }
        }
    },
}

</script>

<template>   
    <v-container id="container-web">
        <AppContentHeader />
        <AppContentHeaderLoketDigital />
         <div class="content">
 
            <slot/>
         
             <AppContentFooter />
         </div>
 
         <AppTrackingCode />
    </v-container>
 </template>
<script>
     import AppContentHeader from '@/components/AppContentHeader'
     import AppContentFooter from '@/components/AppContentFooter'
     import AppContentHeaderLoketDigital from '@/components/AppContentHeaderLoketDigital'
 
     import AppTrackingCode from '@/components/AppTrackingCode';
 
     export default {
         name: 'AppLayoutMain',
         components:
         {
             AppContentHeader,
             AppContentFooter,
             AppContentHeaderLoketDigital,
             AppTrackingCode
         }
     }
 </script>
 <style >
     .content{
         width: 80%;
         margin: auto;
     }

     
     body,html,ul,ol,li,h1,h2,h3,form,p{margin:0;padding:0;}img{border:0;}
      textarea {font-family: inherit;font-size: inherit;}
      *:focus {outline: 0;}
      /*------------------------------------------------------------------*/
      /* Default Styles													*/
      /*------------------------------------------------------------------*/


      body {font-family: Arial, Verdana, sans serif;font-size: 14px;color: #333;margin: 0 0 160px 0;padding: 0;background: url('~@/assets/images/bg.png') #ccc;}
      .name {display:none;}

      #fnew, #fraise, #fchange {display:none;}

      .hide {display:none;}
      .show {display:block!important;}

      hr {color: #d5d5d5; background-color: #d5d5d5; border: 1px solid #d5d5d5;border-style: none none solid;margin: 26px 0 16px 0;padding: 0px;}

      span {font-weight:bold;}

      .important {box-sizing: border-box;font-size:14px;margin-bottom:14px;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;background:#ff9b9b url('~@/assets/images/icon_important_white.png') no-repeat 6px 6px;font-weight:normal;width:100%;line-height:20px;color:#fff;}
      .important p {padding:6px 12px 6px 30px;color:#fff;}

      #email {font-size:18px;background:#eee;box-sizing:border-box;padding: 12px 0 12px 0;margin:0 0 10px 0;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;}

      p {color:#555;margin:15px 0 12px 0;}
      textarea {font-family: Arial, Verdana, sans serif;height:200px;border:1px solid #999;margin:4px 0 12px 0;background:#fcfcfc;padding:10px 10px 10px 10px;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;width:100%;box-sizing: border-box;resize: none;}
      ul {margin:20px 0 20px 20px;}
      ul li {list-style:square;margin-bottom:12px;}
      strong {font-size:18px;color:#429cc6;}

      .button {text-decoration:none;width:100%;display: block;box-sizing: border-box;background:#429cc6;border-radius: 3px;margin:0 0 10px 0;color:#fff !important;text-align:center;font-size:22px;padding:12px 0 12px 0;opacity: 1;transition: background-color .25s ease-in-out;-moz-transition: background-color .25s ease-in-out;-webkit-transition: background-color .25s ease-in-out;}
      .button:hover {background-color:#ff653a;box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;}

      .pdf {background-image: url('~@/assets/images/icon_pdf.png');background-repeat:no-repeat;background-position:top 8px left 9px;background-size: 30px 30px;}
      .pdf:hover {}

      .online {background-color:#007ea1;background-image: url('~@/assets/images/icon_form.png');background-repeat:no-repeat;background-position:top 8px left 9px;background-size: 30px 30px;}
      .online:hover {}

      .loader {text-align:center;display:none;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;background-color:#e7f2f9;border:1px solid #80a3b6;}
      .table_full {width:100%;}

      #lang {background:#000;float:right;color:#fff;padding:4px 12px 4px 12px;opacity:0.2;}
      #lang:hover {opacity:0.8;}
      #lang a {color:#fff;text-decoration:none;opacity:0.8;}
      #lang a:hover {opacity:1;}

      .amt {width:100%;margin:30px 0 30px 0;}
      .amt thead {font-weight:bold;}
      .amt td:first-child {width:235px;}
      .dincome {width:180px!important;}


      ol.style01 {
        max-width: 350px;
        counter-reset: my-awesome-counter;
        list-style: none;
        padding-left: 40px;
      }
      ol.style01 li {
        margin: 0 0 1.5rem 0;
        counter-increment: my-awesome-counter;
        position: relative;
      }
      ol.style01 li::before {
        content: counter(my-awesome-counter);
        color: #fff;
        font-size: 1.5rem;
        font-weight: bold;
        position: absolute;
        --size: 32px;
        left: calc(-1 * var(--size) - 10px);
        line-height: var(--size);
        width: var(--size);
        height: var(--size);
        top: 0;
        transform: rotate(0deg);
        background: #8fbdd8;
        border-radius: 50%;
        text-align: center;
        
      }


      /*------------------------------------------------------------------*/
      /* Layout Styles													*/
      /*------------------------------------------------------------------*/

      #container-web {line-height:22px;background:#fff;position:relative;;margin:0 auto;width:1000px;-moz-box-shadow: 0 2px 4px rgba(0,0,0,0.5);-webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.5);box-shadow: 0 2px 4px rgba(0,0,0,0.5);-webkit-border-radius: 0 0 3px 3px;-moz-border-radius: 0 0 3px 3px;border-radius: 0 0 3px 3px;}
      #header {width:100%;height:128px;background:#007da3;box-sizing:border-box;}
      #header h1 {width: 100%;height: 128px;margin:0;padding:0;}
      #header h1 a {background: url('~@/assets/images/logo_das.png') no-repeat center top 14px;background-size:200px 96px;text-indent: -1000em;display: block;width: 100%;height: 100%;}

      #icon {text-align:center;}
      #icon img {width:50px;height:50px;}

      .content {padding:0 25px 30px 25px;}
      .content #preface {box-sizing:border-box;padding:30px 10px 10px 10px;}
      .content #preface p {margin:0 0 14px 0;}
      .content #preface h4 {color:#429cc6;font-weight:normal;font-size:18px;margin:16px 0 4px 0;}

      #logos {text-align:center;box-sizing:border-box;padding:0 0 0 0;}
      #notice {box-sizing:border-box;padding:30px 0 30px 0;}

      h2#htitle {width:100%;margin: 0 0 20px 0;font-style:italic;font-size:22px;font-weight:normal;text-align:center;color:#fff;background:#91bcda;padding:8px 0 8px 0;z-index:1;line-height:28px;}
      h2#htitle a {color:#fff;text-decoration:none;}

      h3 {color:#000;font-weight:normal;font-size:20px;margin:14px 0 5px 0;}

      a {color:#32a9c0;}

      #notes {padding:3px 30px 3px 30px;text-align: center;}
      .note {font-size:14px;padding:6px 12px 6px 12px;margin-bottom:14px;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;background:#94c1e4;color:#fff;font-weight:normal;width:200px;line-height:20px;}
      .note2 {font-size:14px;margin-bottom:14px;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;background:#429cc6 url('~@/assets/images/icon_important_white.png') no-repeat 6px 6px;color:#fff;font-weight:normal;width:100%;line-height:20px;color:#fff;}
      .note2 p {padding:6px 12px 6px 30px;color:#fff;}

      .note3 {font-size:14px;margin-bottom:14px;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;background:#9dbdcf url('~@/assets/images/icon_important_white.png') no-repeat 6px 6px;color:#fff;font-weight:normal;width:100%;line-height:20px;color:#fff;}
      .note3 p {padding:6px 12px 6px 30px;color:#fff;}

      .idtag {font-weight: normal;font-size:18px;box-sizing: border-box;display:inline-block;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;color:#fff;background:#437c9e;padding:4px 10px 4px 10px;}

      /*------------------------------------------------------------------*/
      /* form styling														*/
      /*------------------------------------------------------------------*/

      form#register {}
      form#register div {margin:10px 0;}
      form#register hr {color: #fff; background-color: #fff; border: 1px dashed #b8b8b8;border-style: none none dashed;margin: 16px 0 16px 0;padding: 0px;}
      form#register em {color:#429cc6;padding:10px 0 10px 0;display:block;font-size:12px;}

      form#register fieldset {border:1px dashed #ccc;padding:10px;margin-bottom:20px;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;margin-top:30px;}
      form#register legend {color:#91bcda;font-size:18px;font-weight:normal;}

      form#register input, form#register select, form#register textarea {color:#333;font-size:18px;border:1px solid #91bcda;background:#fff;padding:20px;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;width:100%;box-sizing: border-box;}
      .field {border:1px solid #429cc6;padding:20px;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;width:100%;box-sizing: border-box;background:#fff}

      ::-webkit-input-placeholder {color: #91bcda;}
      ::-moz-placeholder {color: #91bcda;}
      :-ms-input-placeholder {color: #91bcda;}
      :-moz-placeholder {color: #91bcda;}

      form#register label {margin-right:20px;display:block;margin:17px 0 0 0;}

      /*------------------------------------------------------------------*/
      /* Referral Part													*/
      /*------------------------------------------------------------------*/

      .referral {width:100%;margin-bottom:30px;}

      form#register input[type="radio"], form#register input[type="checkbox"] {display:none;}
      form#register input[type="radio"] + label span {display:inline-block;width:19px;height:19px;margin:-1px 0 0 0;vertical-align:middle;background:url('~@/assets/images/check_radio_sheet.png') -38px top no-repeat;cursor:pointer;margin:-4px 6px 0 0;}
      form#register input[type="checkbox"] + label span {display:inline-block!important;width:19px;height:19px;margin:-1px 0 0 0;vertical-align:middle;background:url('~@/assets/images/check_radio_sheet.png') left top no-repeat;cursor:pointer;margin:-4px 3px 0 0;}
      form#register input[type="radio"]:checked + label span {background:url('~@/assets/images/check_radio_sheet.png') right top no-repeat;}
      form#register input[type="checkbox"]:checked + label span {background:url('~@/assets/images/check_radio_sheet.png') -19px top no-repeat;}

      #other_referral_name {margin:20px 0 20px 0;}


      form#register #submit {width:100%;margin:0;color:#fff;background:#429cc6;cursor:pointer;border:1px solid #429cc6;}
      form#register #submit:hover {background:#ff653a;color:#fff;border:1px solid #ff653a;}

      .required {background-image: url('~@/assets/images/icon_required.svg')!important;background-repeat:no-repeat!important;background-position:right 18px top 24px!important;background-size:13px 12px!important;}

      /*------------------------------------------------------------------*/
      /* Errors															*/
      /*------------------------------------------------------------------*/

      form#register .form_error {border:1px solid #3492b7!important;background-color:#d9ebf9!important;}
      form#register .error_msg {margin-top:-2px;display:inline-block;padding:16px 10px 4px 10px;margin:0!important;background: url('~@/assets/images/arrow_top.png') left top no-repeat;height:20px;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;text-align:center;}
      form#register .long {width:604px;}
      form#register .form_error_text {color:#fff;}

      /*------------------------------------------------------------------*/
      /* media breakpoints												*/
      /*------------------------------------------------------------------*/

      @media only screen and (min-width: 180px) and (max-width: 590px) { 

          #container {width:100%!important;background-position:right -24px;padding:0 5px 5px 5px;}
          h1 {top:1px;left:20px}
          h1 img{width:70px;height:auto;}
          h2 {width:100%;font-size:16px;top:48px;
          }

          #app{
            width: 100%;
          }

          .content{
            width: 100% !important;
            padding: 0 !important;
          }

      }
 </style>
 
<template>
        <v-snackbar 
            :timeout="datos.timeout" 
            v-model="datos.estado"
            v-if="datos.active"
            fixed
            centered
            :color="datos.color" 
            elevation="24">
            <v-row>
                <v-col cols="8"> {{ datos.text }}</v-col>
                <v-col cols="4" class="text-right"> <v-icon large>{{datos.icon}}</v-icon> </v-col>
            </v-row>            
        </v-snackbar>
</template>

<script>
export default {
    props:['datos']

}
</script>

<style>

</style>